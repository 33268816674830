import React, { useContext, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import PaginatedItems from "../../../components/pagination/PaginatedItems";
import { toast } from "react-toastify";
import { UseAuth } from "../../../utils/UseAuth";
import { TokenContext } from "../../../utils/TokenContext";
import { SVC } from "../../../api/service";

export default function ServiceList() {
  // states
  const [id, setId] = useState("");
  const [service, setService] = useState([]);
  //   const [count, setCount] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const [validate, setValidate] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [refreshAdd, setRefreshAdd] = useState(false);
  const [refreshUpdate, setRefreshUpdate] = useState(false);
  const [refreshDelete, setRefreshDelete] = useState(false);
  const [data, setData] = useState({
    _id: "",
    title: "",
    isPurged: false,
  });
  // states

  //constant variables
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const topSessionRef = useRef(null);
  const tableSessionRef = useRef(null);
  const { token } = useContext(TokenContext);
  const endOffset = itemOffset + itemsPerPage;
  const { checkAccessTokenValidity } = UseAuth();
  const currentItems = service.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(service.length / itemsPerPage);

  //   emp.sort((a, b) => {
  //     const dateA = a.date.split("T")[0];
  //     const dateB = b.date.split("T")[0];
  //     if (dateA < dateB) {
  //       return 1;
  //     }
  //     if (dateA > dateB) {
  //       return -1;
  //     }
  //     return 0;
  //   });
  //constant variables

  //dependent functions
  const scrollToSection = () => {
    topSessionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const scrollToTableSection = () => {
    tableSessionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const backToAdd = (item) => {
    setIsEdit(false);
    clearData();
  };
  const clearData = () => {
    setData({
      ...data,
      title: "",
      isPurged: false,
    });
  };

  const handleEdit = (item) => {
    scrollToSection();
    setIsEdit(true);
    setData({
      ...data,
      _id: item._id,
      title: item.title,
      isPurged: false,
    });
    data.title = item.title;
  };
  //dependent functions

  //delete
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "You are about to delete this data! Are you sure?"
    );

    if (confirmed) {
      try {
        const res = await SVC.GET_BY_ID(id);
        if (res.status !== 200) {
          throw new Error("Failed to fetch services information");
        } else {
          var req = {
            title: res.data[0].title,
            isPurged: true,
          };
          deleteData(req, id);
        }
      } catch (error) {
        // console.log("Error loading topics: ", error);
      }
    }
  };
  const deleteData = async (req, id) => {
    try {
      const updateRes = await SVC.DELETE(req, id, token);
      if (updateRes.status === 200) {
        clearData();
        setIsEdit(false);
        getServiceList();
        setRefreshDelete(!refreshDelete);
        setReRunData(null);
        toast.success("Service Deleted");
        navigate("/service");
      } else {
        toast.error("Failed to delete data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(req);
        setId(id);
        setRefreshDelete(!refreshDelete);
      }
    }
  };
  //delete

  //update
  const updateData = async (values) => {
    setLoading(true);
    try {
      const res = await SVC.UPDATE(values, data._id);
      setLoading(false);
      clearData();
      setIsEdit(false);
      if (res.status === 200) {
        setRefreshUpdate(!refreshUpdate);
        toast.success("Service Updated");
        navigate("/service");
      } else {
        toast.error("Failed to updated data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefreshUpdate(!refreshUpdate);
      }
    }
  };
  const handleUpdate = async () => {
    if (data.title !== "") {
      setValidate(false);
      setLoading(true);
      var req = {
        title: data.title,
        isPurged: false,
      };
      updateData(req);
    } else {
      setValidate(true);
    }
  };
  //update

  ///add
  const addData = async (values) => {
    console.log(values, "values");
    setLoading(true);
    try {
      const res = await SVC.ADD(values, token);
      setReRunData(null);
      clearData();
      if (res.status === 200 || res.status === 201) {
        if (res.status === 200) {
          getServiceList();
        }
      } else {
        toast.error("Failed to create data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response && error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefreshAdd(!refreshAdd);
      }
    }
  };
  const handleAdd = async () => {
    if (data.title !== "") {
      setValidate(false);
      var req = {
        title: data.title,
        isPurged: false,
      };
      addData(req);
      setLoading(false);
      setRefreshAdd(!refreshAdd);
      toast.success(`New Service Created : ${req.title}`);
      navigate("/service");
    } else {
      setValidate(true);
    }
  };
  ///add

  //get
  const getServiceList = async () => {
    try {
      const res = await SVC.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setService(res.data);
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  const getSearchServiceList = async (name) => {
    try {
      const res = await SVC.SEARCH(name);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setService(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  //get

  //useEffect
  useEffect(() => {
    if (reRunData !== null) deleteData(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDelete]);
  useEffect(() => {
    if (reRunData !== null) updateData(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUpdate]);
  useEffect(() => {
    if (reRunData !== null) addData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAdd]);
  useEffect(() => {
    if (searchVal === "") {
      getServiceList();
    } else {
      getSearchServiceList(searchVal);
    }
  }, [refreshAdd, refreshDelete, refreshUpdate, searchVal]);
  //useEffect

  return (
    <div>
      <div ref={topSessionRef}>
        <Breadcrumbs pageName="Services" />
      </div>
      {!isEdit && (
        <div className="rounded-sm mb-5 border border-stroke bg-white px-5 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="max-w-full overflow-x-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 ">
              <div className="flex flex-col ">
                <div className="px-2 py-2">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Title <span className="text-meta-1">*</span>
                  </label>
                  <input
                    onChange={(e) => {
                      setData({
                        ...data,
                        title: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="Enter name of service"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="title"
                    value={data.title}
                  />
                  {data.title === "" && validate && (
                    <p className="text-red text-sm mt-1">* Required</p>
                  )}
                </div>
              </div>

              <div className="flex flex-col h-auto sm:pt-7">
                <div className="px-2 py-3 h-auto">
                  <button
                    onClick={() => {
                      handleAdd();
                    }}
                    type="submit"
                    className="border-[1.5px] border-primary flex w-full justify-center rounded bg-primary p-3 font-medium text-white"
                  >
                    {loading ? (
                      <div className="flex h-auto items-center justify-center ">
                        <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                      </div>
                    ) : (
                      "Add Data"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isEdit && (
        <div className="rounded-sm border border-stroke bg-white px-5 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="max-w-full overflow-x-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <div className="flex flex-col">
                <div className="px-2 py-2">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Title <span className="text-meta-1">*</span>
                  </label>
                  <input
                    onChange={(e) => {
                      setData({
                        ...data,
                        title: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="Enter name of service"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="title"
                    value={data.title}
                  />
                </div>
              </div>

              <div className="flex flex-col h-auto sm:pt-7">
                <div className="px-2 py-3 h-auto">
                  <button
                    onClick={() => {
                      handleUpdate();
                    }}
                    type="submit"
                    className="border-[1.5px] border-primary flex w-full justify-center rounded bg-primary p-3 font-medium text-white"
                  >
                    {loading ? (
                      <div className="flex h-auto items-center justify-center ">
                        <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                      </div>
                    ) : (
                      "Update Data"
                    )}
                  </button>
                  <button
                    onClick={() => {
                      backToAdd();
                    }}
                    type="button"
                    className="flex w-full justify-center py-3 font-sm text-sm text-red"
                  >
                    Add New
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div ref={tableSessionRef}>
        <div className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="max-w-full overflow-x-auto">
            <input
              onChange={(e) => {
                setSearchVal(e.target.value);
              }}
              value={searchVal}
              type="text"
              className="w-full mb-4 rounded-xl border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              placeholder="Search by title..."
            />
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 w-full text-left dark:bg-meta-4">
                  <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                    Title
                  </th>
                  <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-5 text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems !== undefined && (
                  <>
                    {currentItems.map((data) => (
                      <tr key={data._id}>
                        <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                          <h5 className="font-medium text-black dark:text-white">
                            {data.title}
                          </h5>
                        </td>

                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <div className="flex items-center justify-center space-x-3.5">
                            <button
                              className="hover:text-primary"
                              onClick={() => handleEdit(data)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="fill-current"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  fill=""
                                  d="M13.6568542,2.34314575 C14.4379028,3.12419433 14.4379028,4.39052429 13.6568542,5.17157288 L6.27039414,12.558033 C5.94999708,12.87843 5.54854738,13.105727 5.10896625,13.2156223 L2.81796695,13.7883721 C2.45177672,13.8799197 2.12008033,13.5482233 2.21162789,13.182033 L2.78437771,10.8910338 C2.894273,10.4514526 3.12156995,10.0500029 3.44196701,9.72960586 L10.8284271,2.34314575 C11.6094757,1.56209717 12.8758057,1.56209717 13.6568542,2.34314575 Z M10.1212441,4.46435931 L4.14907379,10.4367126 C3.95683556,10.6289509 3.82045738,10.8698207 3.75452021,11.1335694 L3.38388341,12.6161166 L4.86643062,12.2454798 C5.1301793,12.1795426 5.37104912,12.0431644 5.56328736,11.8509262 L11.5352441,5.87835931 L10.1212441,4.46435931 Z M11.5355339,3.05025253 L10.8282441,3.75735931 L12.2422441,5.17135931 L12.9497475,4.46446609 C13.3402718,4.0739418 13.3402718,3.44077682 12.9497475,3.05025253 C12.5592232,2.65972824 11.9260582,2.65972824 11.5355339,3.05025253 Z"
                                ></path>
                              </svg>
                            </button>
                            <button
                              className="hover:text-primary"
                              onClick={() => handleDelete(data._id)}
                            >
                              <svg
                                className="fill-current"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                                  fill=""
                                />
                                <path
                                  d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                                  fill=""
                                />
                                <path
                                  d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                                  fill=""
                                />
                                <path
                                  d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                                  fill=""
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <PaginatedItems
            itemsPerPage={itemsPerPage}
            array={service}
            setItemOffset={setItemOffset}
            scrollToSection={scrollToTableSection}
            pageCount={pageCount}
          />
        </div>
      </div>
         
    </div>
  );
}

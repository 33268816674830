import React, { useRef, useState } from "react";
import { IMG } from "../../../assets";
import ServiceView from "./ServiceView";
import BedView from "./BedView";
import { formatDateString } from "../../../utils/FormatDateString";

export default function BookingList() {
  const [showSvc, setShowSvc] = useState(false);
  const [data, setData] = useState({
    date: formatDate(new Date()),
  });
  const endDateRef = useRef(null);
  const handleEndDate = () => {
    endDateRef.current.showPicker();
  };
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return (
    <div
      className="h-full w-full min-h-[90vh] relative bg-event py-10 flex justify-center items-center "
      style={{
        backgroundImage: `url(${IMG.BG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="w-full h-full top-0 flex justify-center items-center py-2 px-80">
        <div className="w-full xl:max-w-[1000px] min-w-[700px] xl:min-w-[1000px] bg-white rounded-xl shadow-xl px-15 py-8 drop-shadow-2">
          <div className="w-full">
            <p className="text-3xl font-semibold font-muli">
              Chaletfatma Beach House
            </p>
            <div className="flex flex-row gap-5  w-full mb-5 border-b-2  border-gray mt-5">
              <button
                onClick={() => {
                  setShowSvc(false);
                }}
                className={`${
                  !showSvc ? "border-b-2  text-primary" : "text-gray"
                }  px-1 font-muli text-lg`}
              >
                Beds & Other Amenities
              </button>
              <button
                onClick={() => {
                  setShowSvc(true);
                }}
                className={`${
                  showSvc ? "border-b-2  text-primary" : "text-gray"
                }  px-1 font-muli text-lg`}
              >
                Services
              </button>
            </div>
            <div className="w-full h-full flex ">
              <button
                onClick={handleEndDate}
                className="flex pl-2 pr-15 py-1 border-gray relative cursor-pointer border-2 border-gray rounded-lg"
              >
                <div className="flex items-center justify-center">
                  <div className="w-[auto] h-auto mr-2.5">
                    <img
                      className=" h-full object-cover"
                      src={IMG.CAL}
                      alt="cal"
                    />
                  </div>
                  <div className="font-semibold text-start">
                    <p className="text-[10px]">CHOOSE DATE</p>
                    <p className="text-sm">{formatDateString(data.date)}</p>
                  </div>
                </div>
                <input
                  type="date"
                  ref={endDateRef}
                  onChange={(e) => {
                    setData({
                      ...data,
                      date: e.target.value,
                    });
                  }}
                  className="opacity-0 absolute bg-red h-full w-full top-0 left-0" // Hide the input
                />
              </button>
            </div>
            <div>{!showSvc ? <BedView /> : <ServiceView />}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const SVC = {
  ADD(req, token) {
    const url = `${CONSTANT.URL}service`;
    return API_REQUEST("POST", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  UPDATE(req, id, token) {
    const url = `${CONSTANT.URL}service/${id}`;
    return API_REQUEST("PUT", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}service`;
    return API_REQUEST("GET", url);
  },
  SEARCH(req) {
    const url = `${CONSTANT.URL}service/search/${req}`;
    return API_REQUEST("GET", url);
  },
  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}service/${id}`;
    return API_REQUEST("GET", url);
  },

  DELETE(req, id, token) {
    const url = `${CONSTANT.URL}service/delete/${id}`;
    return API_REQUEST("PUT", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
};

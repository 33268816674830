import React, { useContext, useEffect, useState } from "react";
import { UseAuth } from "../../../utils/UseAuth";
import { useNavigate, useParams } from "react-router-dom";
import { SVC_BOOKING } from "../../../api/svcBooking";
import { toast } from "react-toastify";
import { TokenContext } from "../../../utils/TokenContext";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { formatDateString } from "../../../utils/FormatDateString";

export default function SvcBookingView() {
  const { checkAccessTokenValidity } = UseAuth();
  let { id } = useParams();
  const [data, setData] = useState({
    title: "",
    date: "",
    note: "",
    buyerId: "",
    buyerName: "",
    isRead: false,
    isFinished: false,
    isPurged: false,
  });
  const navigate = useNavigate();
  const { token } = useContext(TokenContext);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const updateData = async (values) => {
    setLoading(true);

    try {
      const res = await SVC_BOOKING.UPDATE(values, id, token);
      setLoading(false);
      if (res.status === 200) {
      } else {
        toast.error("Failed to updated admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefresh(!refresh);
      }
    }
  };

  const finishChange = async () => {
    setLoading(true);
    var req = {
      title: data.title,
      date: data.date,
      note: data.note,
      buyerId: data.buyerId,
      buyerName: data.buyerName,
      isRead: true,
      isFinished: true,
      isPurged: data.isPurged,
    };
    updateData(req);
    navigate("/svc-booking");
  };
  const readChange = async () => {
    var req = {
      title: data.title,
      date: data.date,
      note: data.note,
      buyerId: data.buyerId,
      buyerName: data.buyerName,
      isRead: false,
      isFinished: data.isFinished,
      isPurged: data.isPurged,
    };
    setLoading(true);
    updateData(req);
    navigate("/svc-booking");
  };

  useEffect(() => {
    if (reRunData !== null) updateData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  const getEmp = async (id) => {
    try {
      const res = await SVC_BOOKING.GET_BY_ID(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch employee information");
      }
      var req = {
        title: res.data[0].title,
        date: res.data[0].date,
        note: res.data[0].note,
        buyerId: res.data[0].buyerId,
        buyerName: res.data[0].buyerName,
        isRead: true,
        isFinished: res.data[0].isFinished,
        isPurged: res.data[0].isPurged,
      };
      updateData(req);
      setData({
        ...data,
        title: res.data[0].title,
        date: res.data[0].date,
        note: res.data[0].note,
        buyerId: res.data[0].buyerId,
        buyerName: res.data[0].buyerName,
        isRead: true,
        isFinished: res.data[0].isFinished,
        isPurged: res.data[0].isPurged,
      });

      data.title = res.data[0].title;
      data.date = res.data[0].date;
      data.note = res.data[0].note;
      data.buyerId = res.data[0].buyerId;
      data.buyerName = res.data[0].buyerName;
      data.isRead = true;
      data.isFinished = res.data[0].isFinished;
      data.isPurged = res.data[0].isPurged;
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  useEffect(() => {
    getEmp(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <div>
      <Breadcrumbs pageName="Service Booking Details" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Service Details
          </h3>
        </div>

        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <div className="flex flex-col">
            <div className="px-6.5 py-2">
              <label className="mb-2.5 block text-black dark:text-white">
                Service Title <span className="text-meta-1">*</span>
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {data.title}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-6.5 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Date <span className="text-meta-1">*</span>
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {formatDateString(data.date)}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-6.5 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Booked By <span className="text-meta-1">*</span>
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {data.buyerName}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-6.5 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Status <span className="text-meta-1">*</span>
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {data.isFinished ? "Finished" : "Pending"}
              </p>
            </div>
          </div>

          <div className="flex flex-col h-auto col-span-2">
            <div className="px-6.5 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Note <span className="text-meta-1">*</span>
              </label>
              <p className="w-full h-auto rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {data.note}
              </p>
            </div>
          </div>

          <div className="p-6.5">
            <button
              type="button"
              onClick={() => {
                readChange();
              }}
              className="flex w-full justify-center rounded bg-primary p-3 font-medium text-white"
            >
              {loading ? (
                <div className="flex h-auto items-center justify-center ">
                  <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                </div>
              ) : (
                "Mark as unread"
              )}
            </button>
          </div>
          <div className="p-6.5">
            <button
              onClick={() => {
                finishChange();
              }}
              type="button"
              className="flex w-full justify-center rounded bg-primary p-3 font-medium text-white"
            >
              {loading ? (
                <div className="flex h-auto items-center justify-center ">
                  <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                </div>
              ) : (
                "Mark as finished"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

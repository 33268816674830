import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SESSION } from "../../../api/session";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import BED from "../../../assets/bed.png";
import { formatDateString } from "../../../utils/FormatDateString";

export default function SessionDetails() {
  let { date } = useParams();
  const [data, setData] = useState({});
  const [session, setSession] = useState([]);

  const getByDate = async (date) => {
    try {
      const res = await SESSION.GET_BY_DATE(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch booking information");
      }
      setData(res.data[0]);
      setSession(res.data[0].session);
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };

  useEffect(() => {
    getByDate(date);
  }, [date]);
  console.log("session", data.session);
  return (
    <div>
      <Breadcrumbs
        pageName={`Session Details - ${formatDateString(data.date)}`}
      />
      <div className=" w-full">
        <div className=" gap-5 px-5 pt-6 pb-2.5  sm:px-7.5 xl:pb-1 grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3">
          {data.session !== undefined ? (
            <>
              {data.session.map((item, index) => (
                <div className="p-5 pt-10 w-full h-auto border flex justify-center items-center flex-col border-stroke bg-white shadow-default rounded-md dark:border-strokedark dark:bg-boxdark relative">
                  <div className="w-[30px] h-[30px] bg-primary rounded-full absolute top-2 right-2 flex justify-center items-center">
                    <p className="text-white font-bold text-lg">{index + 1}</p>
                  </div>
                  <p className="text-primary font-bold text-2xl text-center">
                    {item.startTime} to {item.endTime}
                  </p>
                  <div className="w-full bg-black dark:bg-white h-[1px] my-2"></div>
                  <p className="text-xl px-2 font-semibold">BED</p>
                  <div className="w-full bg-black dark:bg-white h-[1px] my-2"></div>
                  <div className="flex items-center justify-between w-full px-5 pb-2">
                    <div className="flex items-center">
                      <div className="flex items-center">
                        <img src={BED} alt="bed" className="h-8" />
                        <p className="text-xl px-2">1 :</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm px-2 font-bold italic">
                          {item.bed1.buyerName !== ""
                            ? item.bed1.buyerName
                            : "No Bookings"}
                        </p>
                      </div>
                    </div>
                    {item.bed1.isBlocked ? (
                      <button className="bg-green px-10 rounded-md text-sm font-semibold py-1 text-white">
                        Unblock
                      </button>
                    ) : (
                      <button className="bg-red px-10 rounded-md text-sm font-semibold py-1 text-white">
                        Block
                      </button>
                    )}
                  </div>
                  <div className="flex items-center justify-start w-full px-5 pb-2">
                    <div className="flex items-center">
                      <img src={BED} alt="bed" className="h-8" />
                      <p className="text-xl px-2">2 :</p>
                    </div>
                    <div className="flex items-center">
                      <p className="text-sm px-2 font-bold italic">
                        {item.bed2.buyerName !== ""
                          ? item.bed2.buyerName
                          : "No Bookings"}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-start w-full px-5 pb-2">
                    <div className="flex items-center">
                      <img src={BED} alt="bed" className="h-8" />
                      <p className="text-xl px-2">3 :</p>
                    </div>
                    <div className="flex items-center">
                      <p className="text-sm px-2 font-bold italic">
                        {item.bed3.buyerName !== ""
                          ? item.bed3.buyerName
                          : "No Bookings"}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-start w-full px-5 pb-2">
                    <div className="flex items-center">
                      <img src={BED} alt="bed" className="h-8" />
                      <p className="text-xl px-2">4 :</p>
                    </div>
                    <div className="flex items-center">
                      <p className="text-sm px-2 font-bold italic">
                        {item.bed4.buyerName !== ""
                          ? item.bed4.buyerName
                          : "No Bookings"}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-start w-full px-5 pb-2">
                    <div className="flex items-center">
                      <img src={BED} alt="bed" className="h-8" />
                      <p className="text-xl px-2">5 :</p>
                    </div>
                    <div className="flex items-center">
                      <p className="text-sm px-2 font-bold italic">
                        {item.bed5.buyerName !== ""
                          ? item.bed5.buyerName
                          : "No Bookings"}
                      </p>
                    </div>
                  </div>
                  <div className="w-full bg-black dark:bg-white h-[1px] my-2"></div>
                  <p className="text-xl px-2 font-semibold">AMENITIES</p>
                  <div className="w-full bg-black dark:bg-white h-[1px] my-2"></div>
                  {item.amenities.length > 0 ? (
                    <>
                      {item.amenities.map((e) => (
                        <div className="flex items-center justify-start w-full px-5 pb-2">
                          <div className="flex items-center">
                            <p className="text-sm px-2"> {e.activiy} :</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-xm px-2 font-bold italic">
                              {e.buyerName}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="flex items-center justify-center w-full px-5 pb-2">
                      <div className="flex items-center">
                        <p className="text-sm px-2 font-bold italic">
                          No Amenities Booked ...
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { BOOKING } from "../../../api/booking";
import { TokenContext } from "../../../utils/TokenContext";
import { UseAuth } from "../../../utils/UseAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const { checkAccessTokenValidity } = UseAuth();
  const navigate = useNavigate();

  const [minDate] = useState(getTodayDate());
  const [data, setData] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    startMeridiem: "",
    endMeridiem: "",
    buyer: "",
  });
  const { token } = useContext(TokenContext);

  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function handleStartDateChange(e) {
    setData({
      ...data,
      startDate: e.target.value,
    });
  }
  function handleEndDateChange(e) {
    setData({
      ...data,
      endDate: e.target.value,
    });
  }

  const meridiem = [
    {
      value: "AM",
      label: "AM",
    },
    {
      value: "PM",
      label: "PM",
    },
  ];
  const time = [
    {
      value: "12.00",
      label: "12.00",
    },
    {
      value: "12.30",
      label: "12.30",
    },
    {
      value: "01.00",
      label: "01.00",
    },
    {
      value: "01.30",
      label: "01.30",
    },
    {
      value: "02.00",
      label: "02.00",
    },
    {
      value: "02.30",
      label: "02.30",
    },
    {
      value: "03.00",
      label: "03.00",
    },
    {
      value: "03.30",
      label: "03.30",
    },
    {
      value: "04.00",
      label: "04.00",
    },
    {
      value: "04.30",
      label: "04.30",
    },
    {
      value: "05.00",
      label: "05.00",
    },
    {
      value: "05.30",
      label: "05.30",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const handleSave = () => {
    console.log("data", data);
    var request = {
      startDate: data.startDate,
      endDate: data.endDate,
      startTime: data.startTime + " " + data.startMeridiem,
      endTime: data.endTime + " " + data.endMeridiem,
      buyer: data.buyer,
      isPurged: false,
    };
    addData(request);
  };
  const addData = async (values) => {
    setLoading(true);
    console.log("values", values);
    console.log("token", token);
    try {
      const res = await BOOKING.ADD(values, token);
      setLoading(false);
      setReRunData(null);
      if (res.status === 200) {
        toast.success("Created New Booking");
        navigate("/");
      } else {
        toast.error("Failed to create new booking");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefresh(!refresh);
      }
    }
  };
  useEffect(() => {
    if (reRunData !== null) addData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <div className="h-auto grid grid-cols-4 gap-5 p-5 py-20">
      <div className="bg-white p-2 shadow-md rounded-lg border-ash border-[2px]">
        <p className="text-xs font-thin pb-1">Check In Date</p>
        <input
          type="date"
          id="dateInput"
          min={minDate}
          onChange={handleStartDateChange}
          className="h-10 px-5 w-full"
        />
      </div>
      <div className="bg-white p-2 shadow-md rounded-lg border-ash border-[2px]">
        <p className="text-xs font-thin pb-1">Check In Time</p>
        <Select
          onChange={(e) => {
            setData({
              ...data,
              startTime: e.value,
            });
          }}
          // value={categoryValue}
          placeholder="Choose a time"
          options={time}
          className=""
        />
      </div>
      <div className="bg-white p-2 shadow-md rounded-lg border-ash border-[2px]">
        <p className="text-xs font-thin pb-1">Check In Meridiem</p>
        <Select
          onChange={(e) => {
            setData({
              ...data,
              startMeridiem: e.value,
            });
          }}
          // value={categoryValue}
          placeholder="Choose a meridiem"
          options={meridiem}
          className=""
        />
      </div>
      <div className="bg-white p-2 shadow-md rounded-lg border-ash border-[2px]">
        <p className="text-xs font-thin pb-1">Buyer Name</p>
        <input
          type="text"
          id="name"
          placeholder="Enter Buyer Name"
          onChange={(e) => {
            setData({
              ...data,
              buyer: e.target.value,
            });
          }}
          className="h-10 px-5 w-full"
        />
      </div>
      <div className="bg-white p-2 shadow-md rounded-lg border-ash border-[2px]">
        <p className="text-xs font-thin pb-1">Check Out Date</p>
        <input
          type="date"
          id="dateInput"
          min={minDate}
          onChange={handleEndDateChange}
          className="h-10 px-5 w-full"
        />
      </div>
      <div className="bg-white p-2 shadow-md rounded-lg border-ash border-[2px]">
        <p className="text-xs font-thin pb-1">Check Out Time</p>
        <Select
          onChange={(e) => {
            setData({
              ...data,
              endTime: e.value,
            });
          }}
          // value={categoryValue}
          placeholder="Choose a time"
          options={time}
          className=""
        />
      </div>
      <div className="bg-white p-2 shadow-md rounded-lg border-ash border-[2px]">
        <p className="text-xs font-thin pb-1">Check Out Meridiem</p>
        <Select
          onChange={(e) => {
            setData({
              ...data,
              endMeridiem: e.value,
            });
          }}
          // value={categoryValue}
          placeholder="Choose a meridiem"
          options={meridiem}
          className=""
        />
      </div>
      <div className="bg-white p-2 shadow-md rounded-lg border-ash border-[2px]">
        <button
          className="bg-primary w-full h-full rounded-lg text-white font-bold"
          onClick={() => {
            handleSave();
          }}
        >
          {loading ? (
            <div className="flex h-auto items-center justify-center ">
              <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
            </div>
          ) : (
            "Save Data"
          )}
        </button>
      </div>
    </div>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/user/home/Home";
import NoPage from "../pages/unauth/NoPage";
import Service from "../pages/user/service/Service";
import Header from "../components/user/Header";
import Footer from "../components/user/Footer";
import BookingList from "../pages/user/bookingList/BookingList";
import MyBookings from "../pages/user/myBookings/MyBookings";

export default function UserLayout() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="book-now" element={<Home />} />
          <Route path="booking-list" element={<BookingList />} />
          <Route path="my-booking" element={<MyBookings />} />
          <Route path="service" element={<Service />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import { IMG } from "../../../assets";
import { BOOKING } from "../../../api/booking";
import { AuthContext } from "../../../utils/AuthContext";
import { SVC_BOOKING } from "../../../api/svcBooking";
import { formatDateString } from "../../../utils/FormatDateString";
import { useNavigate } from "react-router-dom";
import { UseAuth } from "../../../utils/UseAuth";
import { toast } from "react-toastify";
import { TokenContext } from "../../../utils/TokenContext";

export default function MyBookings() {
  const { user } = useContext(AuthContext);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const [data, setData] = useState([]);
  data.sort((a, b) => {
    const dateTimeA = new Date(a.date).getTime();
    const dateTimeB = new Date(b.date).getTime();

    return dateTimeB - dateTimeA;
  });
  const [book, setBook] = useState([]);
  const [svc, setSvc] = useState([]);
  const { checkAccessTokenValidity } = UseAuth();
  const { token } = useContext(TokenContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [refreshUpdate, setRefreshUpdate] = useState(false);
  const [refreshDelete, setRefreshDelete] = useState(false);
  const [reRunUpdate, setReRunUpdate] = useState(null);
  const [updateId, setUpdateId] = useState("");
  const getBookings = async (id) => {
    try {
      const res = await BOOKING.GET_USER_BOOKING(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch amenities information");
      } else {
        setData(res.data);
      }
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const getAllBookings = async (id) => {
    try {
      const res = await BOOKING.GET_BY_USER(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch amenities information");
      } else {
        setBook(res.data);
      }
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const getAllSvc = async (id) => {
    try {
      const res = await SVC_BOOKING.GET_BY_USER(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch amenities information");
      } else {
        setSvc(res.data);
      }
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };

  useEffect(() => {
    getBookings(user._id);
    getAllBookings(user._id);
    getAllSvc(user._id);
  }, [refreshUpdate, refreshDelete]);
  //create an array using starting dates

  //delete session

  const updateData = async (values, id) => {
    setLoading(true);
    try {
      const res = await BOOKING.UPDATE(values, id, token);
      if (res.status === 200) {
        setReRunUpdate(null);
        setRefreshUpdate(!refreshUpdate);
        setLoading(false);
        toast.success("Booking Cancelled!");
        navigate("/my-booking");
      } else {
        toast.error("Failed to updated data");
        setLoading(false);
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunUpdate(values);
        setRefreshUpdate(!refreshUpdate);
      }
    }
  };
  useEffect(() => {
    if (reRunUpdate !== null) updateData(reRunUpdate, updateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUpdate]);
  const deleteSvc = async (req, id) => {
    try {
      const updateRes = await SVC_BOOKING.DELETE(req, id, token);
      if (updateRes.status === 200) {
        setRefreshDelete(!refreshDelete);
        setReRunData(null);
        toast.success("Booking Deleted");
        navigate("/my-booking");
      } else {
        toast.error("Failed to delete data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(req);
        setRefreshDelete(!refreshDelete);
      }
    }
  };
  //delete
  useEffect(() => {
    if (reRunData !== null) deleteSvc(reRunData, updateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDelete]);
  const deleteData = async (req, id) => {
    try {
      const updateRes = await BOOKING.DELETE(req, id, token);
      if (updateRes.status === 200) {
        setRefreshDelete(!refreshDelete);
        setReRunData(null);
        toast.success("Booking Deleted");
        navigate("/my-booking");
      } else {
        toast.error("Failed to delete data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(req);
        setRefreshDelete(!refreshDelete);
      }
    }
  };
  //delete
  useEffect(() => {
    if (reRunData !== null) deleteData(reRunData, updateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDelete]);

  const handleCancelBed = async (item, date) => {
    const confirmed = window.confirm(
      "You are about to remove this bed! Are you sure about this?"
    );
    if (confirmed) {
      cancelBed(item, date);
    }
  };
  const handleCancelAme = async (item, date) => {
    const confirmed = window.confirm(
      "You are about to remove this amenity! Are you sure about this?"
    );
    if (confirmed) {
      cancelAme(item, date);
    }
  };
  const handleCancelSvc = async (item, date) => {
    const confirmed = window.confirm(
      "You are about to remove this service! Are you sure about this?"
    );
    if (confirmed) {
      cancelSvc(item, date);
      // deleteSvc(item, date);
    }
  };

  const cancelAme = (item, date) => {
    let filteredByDate = book.filter((data) => {
      return data.startDate === date && data.startTime === item.startTime;
    });
    filteredByDate.map(async (element) => {
      await element.amenities.map((e) => {
        if (e.activity === item.itemName) {
          const indexToRemove = element.amenities.indexOf(e);
          element.amenities.splice(indexToRemove, 1);

          var req = {
            startDate: element.startDate,
            endDate: element.endDate,
            startTime: element.startTime,
            endTime: element.endTime,
            buyerId: element.buyerId,
            buyerName: element.buyerName,
            bed: element.bed,
            amenities: element.amenities,
            isPurged: element.isPurged,
          };
          if (element.amenities.length === 0 && element.bed.length === 0) {
            deleteData(req, element._id);
          } else {
            updateData(req, element._id);
          }
          setUpdateId(element._id);
          console.log("req", req);
        }
      });
    });
    console.log("book", book);
  };
  const cancelSvc = (item, date) => {
    let filteredByDate = svc.filter((data) => {
      return (
        data.date === date &&
        data.title === item.itemName &&
        data.note === item.desp
      );
    });

    console.log("filteredByDate", filteredByDate);
    filteredByDate.map(async (element) => {
      var req = {
        title: element.title,
        date: element.date,
        note: element.note,
        buyerId: element.buyerId,
        buyerName: element.buyerName,
        isRead: element.isRead,
        isFinished: element.isFinished,
        isPurged: element.isPurged,
      };
      deleteSvc(req, element._id);
      setUpdateId(element._id);
    });
  };
  const cancelBed = (item, date) => {
    console.log("book", book);
    console.log("item", item);
    let filteredByDate = book.filter((data) => {
      return data.startDate === date && data.startTime === item.startTime;
    });
    console.log("filteredByDate", filteredByDate);
    filteredByDate.map((element) => {
      element.bed.map((e) => {
        if (e.bedName === item.itemName) {
          console.log("element.bed before", element.bed);
          const indexToRemove = element.bed.indexOf(e);
          element.bed.splice(indexToRemove, 1);
          console.log("element.bed after", element.bed);
          var req = {
            startDate: element.startDate,
            endDate: element.endDate,
            startTime: element.startTime,
            endTime: element.endTime,
            buyerId: element.buyerId,
            buyerName: element.buyerName,
            bed: element.bed,
            amenities: element.amenities,
            isPurged: element.isPurged,
          };
          setUpdateId(element._id);
          console.log("req", req);
          if (element.amenities.length === 0 && element.bed.length === 0) {
            deleteData(req, element._id);
          } else {
            updateData(req, element._id);
          }
        }
      });
    });
  };
  //delete session
  return (
    <>
      <div className="h-full w-full min-h-[90vh] flex md:hidden flex-col justify-center items-start px-2 pt-2">
        <img
          src={IMG.BG}
          alt="bg"
          className="w-full object-cover rounded-t-xl"
        />
        <div className="w-full h-full relative top-[-20px] flex justify-center items-center md:px-80">
          <DataDiv
            data={data}
            currentDate={currentDate}
            handleCancelBed={handleCancelBed}
            handleCancelAme={handleCancelAme}
            handleCancelSvc={handleCancelSvc}
          />
        </div>
      </div>

      <div
        className="h-full w-full min-h-[90vh] relative bg-event py-10 hidden md:flex justify-center items-center "
        style={{
          backgroundImage: `url(${IMG.BG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="w-full h-full top-0 flex justify-center items-center py-2 md:px-80 ">
          <DataDiv
            data={data}
            currentDate={currentDate}
            handleCancelBed={handleCancelBed}
            handleCancelAme={handleCancelAme}
            handleCancelSvc={handleCancelSvc}
          />
        </div>
      </div>
    </>
  );
}
const DataDiv = ({
  data,
  currentDate,
  handleCancelBed,
  handleCancelAme,
  handleCancelSvc,
}) => {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the sensitivity as needed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className="w-full xl:max-w-[1000px] md:min-w-[750px] lg:min-w-[900px] xl:min-w-[1000px] bg-white rounded-xl shadow-xl px-5 lg:px-15 py-8 drop-shadow-2">
      <div className="w-full">
        <p className="text-2xl md:text-3xl font-semibold font-muli pb-5">
          Chaletfatma Beach House
        </p>
      </div>

      {data.map((val) => (
        <>
          <div className="w-full border-gray border-[1px] rounded-md p-2 mb-5">
            <div className="flex justify-center items-center">
              <img className="object-cover w-6" src={IMG.CAL} alt="" />
              <p className="text-xl font-bold py-2 px-2">
                {formatDateString(val.date)}
              </p>
              <img className="object-cover w-6" src={IMG.CAL} alt="" />
            </div>
            <div className="w-full bg-gray h-[1px] mb-5" />
            <div
              ref={containerRef}
              className="overflow-x-auto no-scrollbar"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
            >
              <div className="flex items-center justify-start w-full">
                <div className="flex flex-row space-x-5  h-full">
                  {val.data.map((itm) => (
                    <div className="bg-ash text-center relative border-[1px] border-semiash p-2 rounded-md w-[150px] h-auto flex items-center justify-between flex-col">
                      <div className="flex items-center justify-start flex-col">
                        <div className="w-auto h-auto pt-3 pb-2">
                          {itm.type === "bed" && (
                            <img
                              className="object-cover w-10"
                              src={IMG.BED}
                              alt=""
                            />
                          )}
                          {itm.type === "amenities" && (
                            <img
                              className="object-cover w-10"
                              src={IMG.ACT}
                              alt=""
                            />
                          )}
                          {itm.type === "service" && (
                            <img
                              className="object-cover w-10"
                              src={IMG.SVC}
                              alt=""
                            />
                          )}
                        </div>
                        <div className="flex flex-col justify-center items-center ">
                          {itm.type === "service" ? (
                            <div className="flex justify-center items-center pt-2">
                              <p className="text-xs font-bold font-muli  ">
                                Date
                              </p>
                              <img
                                src={IMG.CAL}
                                alt="clk"
                                className="h-4 px-1"
                              />
                              <p className="text-xs font-bold font-muli  ">
                                : {formatDateString(itm.startDate)}
                              </p>
                            </div>
                          ) : (
                            <>
                              <div className="flex justify-center items-center">
                                <p className="text-xs font-bold font-muli  ">
                                  Start
                                </p>
                                <img
                                  src={IMG.CLK}
                                  alt="clk"
                                  className="h-4 px-1"
                                />
                                <p className="text-xs font-bold font-muli  ">
                                  : {itm.startTime}
                                </p>
                              </div>
                              <div className="flex justify-center items-center pt-2">
                                <p className="text-xs font-bold font-muli  ">
                                  End
                                </p>
                                <img
                                  src={IMG.CLK}
                                  alt="clk"
                                  className="h-4 px-1"
                                />
                                <p className="text-xs font-bold font-muli  ">
                                  : {itm.endTime}
                                </p>
                              </div>
                            </>
                          )}

                          <p className="text-md font-black font-muli my-2 italic">
                            {itm.itemName}
                          </p>
                        </div>
                      </div>

                      {new Date(val.date) > currentDate && (
                        <button
                          onClick={() => {
                            if (itm.type === "bed") {
                              handleCancelBed(itm, val.date);
                            } else if (itm.type === "amenities") {
                              handleCancelAme(itm, val.date);
                            } else if (itm.type === "service") {
                              handleCancelSvc(itm, val.date);
                            }
                          }}
                          className="border-red font-semibold border-[1px] rounded-md text-red w-full py-0.5"
                        >
                          REMOVE
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-5">
              {val.data.map((itm) => (
                <div className="bg-ash text-center relative border-[1px] border-semiash p-2 rounded-md w-full h-auto flex items-center justify-between flex-col">
                  <div className="flex items-center justify-start flex-col">
                    <div className="w-auto h-auto pt-3 pb-2">
                      {itm.type === "bed" && (
                        <img
                          className="object-cover w-10"
                          src={IMG.BED}
                          alt=""
                        />
                      )}
                      {itm.type === "amenities" && (
                        <img
                          className="object-cover w-10"
                          src={IMG.ACT}
                          alt=""
                        />
                      )}
                      {itm.type === "service" && (
                        <img
                          className="object-cover w-10"
                          src={IMG.SVC}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="flex flex-col justify-center items-center ">
                      {itm.type === "service" ? (
                        <div className="flex justify-center items-center pt-2">
                          <p className="text-xs font-bold font-muli  ">Date</p>
                          <img src={IMG.CAL} alt="clk" className="h-4 px-1" />
                          <p className="text-xs font-bold font-muli  ">
                            : {itm.startDate}
                          </p>
                        </div>
                      ) : (
                        <>
                          <div className="flex justify-center items-center">
                            <p className="text-xs font-bold font-muli  ">
                              Start
                            </p>
                            <img src={IMG.CLK} alt="clk" className="h-4 px-1" />
                            <p className="text-xs font-bold font-muli  ">
                              : {itm.startTime}
                            </p>
                          </div>
                          <div className="flex justify-center items-center pt-2">
                            <p className="text-xs font-bold font-muli  ">End</p>
                            <img src={IMG.CLK} alt="clk" className="h-4 px-1" />
                            <p className="text-xs font-bold font-muli  ">
                              : {itm.endTime}
                            </p>
                          </div>
                        </>
                      )}

                      <p className="text-md font-black font-muli my-2 italic">
                        {itm.itemName}
                      </p>
                    </div>
                  </div>

                  {new Date(val.date) > currentDate && (
                    <button
                      onClick={() => {
                        if (itm.type === "bed") {
                          handleCancelBed(itm, val.date);
                        } else if (itm.type === "amenities") {
                          handleCancelAme(itm, val.date);
                        } else if (itm.type === "service") {
                          handleCancelSvc(itm, val.date);
                        }
                      }}
                      className="border-red font-semibold border-[1px] rounded-md text-red w-full py-0.5"
                    >
                      REMOVE
                    </button>
                  )}
                </div>
              ))}
            </div> */}
          </div>
          {/* <div className="w-full bg-gray h-[1px] my-2" /> */}
        </>
      ))}
    </div>
  );
};

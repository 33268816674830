import React from "react";
import LoginForm from "./LoginForm";

export default function UserLogin() {
  return (
    <div className="flex flex-row justify-center items-center w-full h-screen ">
      <div className="py-12 flex-1">
        <div className="flex rounded-lg shadow-2xl overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
          <div className="hidden lg:block lg:w-1/2">
            <img
              src="https://www.decoraid.com/wp-content/uploads/2021/12/beach-house-colors-2500x1670.jpeg"
              className="w-full h-full object-cover"
              alt="logo"
            />
          </div>
          <div className="w-full p-8 lg:w-1/2">
            <h2 className="text-2xl font-semibold text-primary text-center">
              CHALET FATMA
            </h2>
            <div className="bg-white flex items-center justify-center mt-4 text-black rounded-lg shadow-md hover:bg-white">
              <h1 className="px-4 py-3 w-5/6 text-center text-gray-600 font-bold">
                Enjoy every moment
              </h1>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <span className="border-b border-primary w-1/5 lg:w-1/4"></span>
              <div className="text-xs text-center text-grey-500 uppercase">
                User Login
              </div>
              <span className="border-b w-1/5 border-primary lg:w-1/4"></span>
            </div>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
}

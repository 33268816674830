import React from "react";

export default function Footer() {
  return (
    <div className="bg-dark h-auto w-full flex justify-center items-center py-20">
      <div className="w-full w-[80%] max-w-screen-2xl flex justify-center items-center">
        <p className="text-white font-thin text-sm">© 2024 Chaletfatma</p>
      </div>
    </div>
  );
}

import { useContext, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { ADMIN } from "../../../api/admin";
import { TokenContext } from "../../../utils/TokenContext";
import Select from "react-select";
import { UseAuth } from "../../../utils/UseAuth";
export default function EmpUpdate() {
  const { checkAccessTokenValidity } = UseAuth();
  let { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { token } = useContext(TokenContext);
  const [refresh, setRefresh] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [data, setData] = useState({
    _id: "",
    name: "",
    password: "",
    role: 0,
    tokenId: "",
    isPurged: false,
  });
  const options = [
    { value: 0, label: "Super Admin" },
    { value: 1, label: "Admin" },
    { value: 2, label: "Booking Clerk" },
    { value: 3, label: "Ticket Inspector" },
  ];
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    role: Yup.number().required("Required"),
  });

  const SelectField = ({ options, form, field, ...props }) => {
    return (
      <Select
        {...props}
        options={options}
        name={field.name}
        value={
          options ? options.find((option) => option.value === field.value) : ""
        }
        onChange={(option) => form.setFieldValue(field.name, option.value)}
        // onBlur={field.onBlur}
      />
    );
  };

  const updateData = async (values) => {
    setLoading(true);

    try {
      const res = await ADMIN.UPDATE(values, values._id, token);
      setLoading(false);
      if (res.status === 200) {
        toast.success("Updated admin");
        navigate("/admin");
      } else {
        toast.error("Failed to updated admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefresh(!refresh);
      }
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    updateData(values);
  };

  useEffect(() => {
    if (reRunData !== null) updateData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  const getEmp = async (id) => {
    try {
      const res = await ADMIN.GET_BY_ID(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch employee information");
      }
      setData({
        ...data,
        _id: res.data[0]._id,
        name: res.data[0].name,
        password: res.data[0].password,
        role: res.data[0].role,
        tokenId: res.data[0].tokenId,
        isPurged: res.data[0].isPurged,
      });

      data._id = res.data[0]._id;
      data.name = res.data[0].name;
      data.password = res.data[0].password;
      data.role = res.data[0].role;
      data.tokenId = res.data[0].tokenId;
      data.isPurged = res.data[0].isPurged;
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  useEffect(() => {
    getEmp(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <div>
      <Breadcrumbs pageName="Update Employee" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Update Details
          </h3>
        </div>
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form className="grid grid-cols-1 gap-9 sm:grid-cols-2">
            <div className="flex flex-col ">
              <div className="px-6.5 py-2">
                <label className="mb-2.5 block text-black dark:text-white">
                  User Name <span className="text-meta-1">*</span>
                </label>
                <Field
                  placeholder="Enter the user name"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  name="name"
                />
                <ErrorMessage
                  component="a"
                  className="text-danger"
                  name="name"
                />
              </div>
            </div>
            <div className="flex flex-col h-auto">
              <div className="px-6.5 py-2 h-auto">
                <label className="mb-2.5 block text-black dark:text-white">
                  Status <span className="text-meta-1">*</span>
                </label>
                <Field
                  name="role"
                  component={SelectField}
                  options={options}
                />
                <ErrorMessage
                  component="a"
                  className="text-danger"
                  name="role"
                />
              </div>
            </div>

            <div className="p-6.5">
              <button
                type="submit"
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? (
                  <div className="flex h-auto items-center justify-center ">
                    <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                  </div>
                ) : (
                  "Update Employee"
                )}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

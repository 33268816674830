// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
    align-items: center;
    color: #808e9b;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
  }
  
  .disabled-page {
    color: #808e9b;
  }
  
  .active {
    border: solid 1px #808e9b;
    border-radius: 40px;
    color: #808e9b;
  }
  
  .next {
    border-left: solid 1px #808e9b;
    font-size: 4px;
    height: 60px;
    position: absolute;
    right: 0;
    width: 150px;
  }
  
  
  .pagination-page {
    font-weight: 700;
  }
  
  .previous {
    border-right: solid 1px #808e9b;
    font-size: 4px;
    height: 60px;
    left: 0;
    position: absolute;
    width: 150px;
  }`, "",{"version":3,"sources":["webpack://./src/components/pagination/pagination.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,aAAa;IACb,eAAe;IACf,YAAY;IACZ,uBAAuB;IACvB,WAAW;EACb;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,8BAA8B;IAC9B,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,YAAY;EACd;;;EAGA;IACE,gBAAgB;EAClB;;EAEA;IACE,+BAA+B;IAC/B,cAAc;IACd,YAAY;IACZ,OAAO;IACP,kBAAkB;IAClB,YAAY;EACd","sourcesContent":[".item {\r\n    align-items: center;\r\n    color: #808e9b;\r\n    cursor: pointer;\r\n    display: flex;\r\n    font-size: 14px;\r\n    height: 40px;\r\n    justify-content: center;\r\n    width: 40px;\r\n  }\r\n  \r\n  .disabled-page {\r\n    color: #808e9b;\r\n  }\r\n  \r\n  .active {\r\n    border: solid 1px #808e9b;\r\n    border-radius: 40px;\r\n    color: #808e9b;\r\n  }\r\n  \r\n  .next {\r\n    border-left: solid 1px #808e9b;\r\n    font-size: 4px;\r\n    height: 60px;\r\n    position: absolute;\r\n    right: 0;\r\n    width: 150px;\r\n  }\r\n  \r\n  \r\n  .pagination-page {\r\n    font-weight: 700;\r\n  }\r\n  \r\n  .previous {\r\n    border-right: solid 1px #808e9b;\r\n    font-size: 4px;\r\n    height: 60px;\r\n    left: 0;\r\n    position: absolute;\r\n    width: 150px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

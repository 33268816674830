import React from "react";
import { Route, Routes } from "react-router-dom";
import ServerTimeout from "../pages/unauth/ServerTimeout";
import NoPage from "../pages/unauth/NoPage";
import UserLogin from "../pages/unauth/user/UserLogin";
import AdminLogin from "../pages/unauth/admin/AdminLogin";

export default function UnAuthLayout() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<UserLogin />} />
        <Route path="admin-login" element={<AdminLogin />} />
        <Route path="user-login" element={<UserLogin />} />
        <Route path="server-timeout" element={<ServerTimeout />} />
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import CAL from "../../../assets/cal.png";
import CLK from "../../../assets/clock.png";
import { IMG } from "../../../assets";
import { formatDateString } from "../../../utils/FormatDateString";
import { AuthContext } from "../../../utils/AuthContext";
import { TokenContext } from "../../../utils/TokenContext";
import { BOOKING } from "../../../api/booking";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { UseAuth } from "../../../utils/UseAuth";
import { BED } from "../../../api/bed";
import { AME } from "../../../api/amenity";
import TimePicker from "react-time-picker";
import { CONSTANT } from "../../../constant/Constant";
import { MobileTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
export default function BedBooking() {
  const [value, onChange] = useState("10:00");
  const { checkAccessTokenValidity } = UseAuth();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { token } = useContext(TokenContext);
  const convertTo12HourFormat = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minutes} ${ampm}`;
  };
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0"); // Ensure two digits for hours
    const minutes = String(now.getMinutes()).padStart(2, "0"); // Ensure two digits for minutes
    return convertTo12HourFormat(`${hours}:00`);
    // return convertTo12HourFormat(`${hours}:${minutes}`);
  };
  const getEndtTime = () => {
    // const now = new Date();
    // now.setMinutes(now.getMinutes() + 60); // Add 30 minutes to the current time
    // const hours = now.getHours();
    // const minutes = String(now.getMinutes()).padStart(2, "0"); // Ensure two digits for minutes
    // const ampm = hours >= 12 ? "PM" : "AM";
    // const hour12 = hours % 12 || 12;
    // return `${hour12}:${minutes} ${ampm}`;
    const now = new Date();
    const nextHour = (now.getHours() + 1) % 24; // Calculate the next hour, considering wrap around to the next day
    const minutes = String(now.getMinutes()).padStart(2, "0"); // Ensure two digits for minutes
    const ampm = nextHour >= 12 ? "PM" : "AM";
    const hour12 = nextHour % 12 || 12;
    return `${hour12}:00 ${ampm}`;
  };
  const [refresh, setRefresh] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [selectedTime, setSelectedTime] = useState(dayjs().startOf("hour"));
  const [data, setData] = useState({
    startDate: formatDate(new Date()),
    endDate: formatDate(new Date()),
    startTime: getCurrentTime(),
    endTime: getEndtTime(),
    buyerId: user._id,
    buyerName: user.fullName,
    bed: [],
    amenities: [],
    isPurged: false,
  });
  const getNextHourTime = (timeString) => {
    if (typeof timeString !== "string") {
      return "Invalid time format";
    }
    const [hours, minutes, ampm] = timeString.split(/:| /);
    let nextHour = parseInt(hours) + 1;
    if (nextHour === 12 && ampm === "AM") {
      return `12:${minutes} PM`;
    } else if (nextHour === 12 && ampm === "PM") {
      return `12:${minutes} AM`;
    } else if (nextHour === 13) {
      nextHour = 1;
      if (ampm === "AM") {
        return `1:${minutes} AM`;
      } else {
        return `1:${minutes} PM`;
      }
    }
    return `${nextHour}:${minutes} ${ampm}`;
  };
  const [added, setAdded] = useState([]);

  const [amenities, setAmenities] = useState([]);
  const [beds, setBeds] = useState([]);

  const [oldBookings, setOldBookings] = useState([]);
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);

  const handleStartDate = () => {
    // startDateRef.current.showPicker();
    if (startDateRef.current && typeof startDateRef.current.showPicker === 'function') {
      try {
        startDateRef.current.showPicker();
      } catch (error) {
        console.error("Error showing date picker: ", error);
      }
    } else {
      // Fallback: Trigger a click on the date input to show the date picker in browsers that don't support showPicker
      startDateRef.current.click();
    }
  };
  const handleEndDate = () => {
    endDateRef.current.showPicker();
  };
  // const handleStartTime = () => {
  //   startTimeRef.current.showPicker();
  // };
  const handleEndTime = () => {
    endTimeRef.current.showPicker();
  };
  const handleAddBeds = (item, index) => {
    const bedArray = data.bed;
    const req = {
      buyerId: user._id,
      buyerName: user.fullName,
      bedName: item.title,
      bedId: item._id,
      isBlocked: false,
    };
    bedArray.push(req);
    added.push(item.title);
    setAdded(added);
    setData({
      ...data,
      bed: bedArray,
    });
    data.bed = bedArray;
  };
  const handleAddAmenities = (item, index) => {
    const itemArray = data.amenities;
    const req = {
      activity: item.title,
      buyerId: user._id,
      itemId: item._id,
      buyerName: user.fullName,
      isBlocked: false,
    };
    itemArray.push(req);
    added.push(item.title);
    setAdded(added);
    setData({
      ...data,
      amenities: itemArray,
    });
    data.amenities = itemArray;
  };
  const handleRemoveAmenities = (e) => {
    const index = data.amenities.findIndex((item) => item.itemId === e._id);
    const indexOf = added.findIndex((item) => item === e.title);
    data.amenities.splice(index, 1);
    added.splice(indexOf, 1);
    setData({
      ...data,
      amenities: data.amenities,
    });
    // data.amenities = data.amenities;
    setAdded(added);
  };
  const handleRemoveBeds = (e) => {
    const index = data.bed.findIndex((item) => item.bedId === e._id);
    const indexOf = added.findIndex((item) => item === e.title);
    data.bed.splice(index, 1);
    added.splice(indexOf, 1);
    setData({
      ...data,
      bed: data.bed,
    });
    // data.bed = data.bed;
    setAdded(added);
  };
  const checkForBooking = (item) => {
    var rtnVal = {
      status: "Add",
      data: [],
    };
    var array = [];
    if (oldBookings.length > 0) {
      var oldRslt = false;
      var userCheck = false;
      oldBookings.map((data) => {
        data.bed.map((itm) => {
          array.push(itm);
        });
      });
      oldRslt = array.some((evt) => evt.bedId === item._id);
      // if true => the data is booked now
      if (oldRslt) {
        var block = filterByBedId(array, item._id);
        return { status: "Block", data: block };
      } else {
        // console.log("in else");
      }
    }
    if (data.bed.length > 0) {
      const rslt = data.bed.some((evt) => evt.bedId === item._id);
      if (rslt) {
        return { status: "Remove", data: [] };
      }
    }
    // if not any of the above => It shows add option
    return rtnVal;
  };

  const filterByBedId = (array, id) => {
    const rtnArray = [];
    array.map((data) => {
      if (data.bedId === id) {
        rtnArray.push(data.buyerName);
      }
    });
    return rtnArray;
  };
  const filterByItemId = (array, id) => {
    const rtnArray = [];
    array.map((data) => {
      if (data.itemId === id) {
        rtnArray.push(data.buyerName);
      }
    });
    return rtnArray;
  };
  const checkForAmenities = (item) => {
    var rtnVal = {
      status: "Add",
      data: [],
    };
    var array = [];
    if (oldBookings.length > 0) {
      var oldRslt = false;
      var userCheck = false;
      oldBookings.map((data) => {
        data.amenities.map((itm) => {
          array.push(itm);
        });
      });
      oldRslt = array.some((evt) => evt.itemId === item._id);
      // if true => the data is booked now
      if (oldRslt) {
        var block = filterByItemId(array, item._id);
        return { status: "Block", data: block };
      } else {
        // console.log("in else");
      }
    }

    if (data.amenities.length > 0) {
      const rslt = data.amenities.some((evt) => evt.itemId === item._id);
      if (rslt) {
        return { status: "Remove", data: [] };
      }
    }
    // if not any of the above => It shows add option
    return rtnVal;
  };
  const checkBookingData = async (req) => {
    try {
      const res = await BOOKING.SEARCH(req);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setOldBookings(res.data);
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  const [loading, setLoading] = useState(false);

  const addData = async (values) => {
    setLoading(true);
    try {
      const res = await BOOKING.ADD(values, token);
      setLoading(false);
      setReRunData(null);
      if (res.status === 200) {
        toast.success("Booking Reserved");
        setOldBookings([]);
        setAdded([]);
        navigate("/");
      } else {
        toast.error("Failed to create new admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefresh(!refresh);
      }
    }
  };
  const handleAddBooking = () => {
    if (added.length > 0) {
      addData(data);
    } else {
      toast.error("Must add at least one item to reserve");
    }
  };
  useEffect(() => {
    var req = {
      startDate: data.startDate,
      endDate: data.endDate,
      startTime: data.startTime,
      endTime: data.endTime,
    };
    checkBookingData(req);
  }, [data]);
  useEffect(() => {
    if (reRunData !== null) addData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const getEmpList = async () => {
    try {
      const res = await BED.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch booking information");
      }
      setBeds(res.data);
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  const getAmiList = async () => {
    try {
      const res = await AME.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch booking information");
      }
      setAmenities(res.data);
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };

  const getImgUrl = (img) => {
    return `${CONSTANT.BASE_URL}/amenity/${img}`;
  };
  useEffect(() => {
    getEmpList();
    getAmiList();
  }, []);

  const [refreshUpdate, setRefreshUpdate] = useState(false);
  const [refreshDelete, setRefreshDelete] = useState(false);
  const [reRunUpdate, setReRunUpdate] = useState(null);
  const [updateId, setUpdateId] = useState("");
  const updateData = async (values, id) => {
    setLoading(true);
    try {
      const res = await BOOKING.UPDATE(values, id, token);
      if (res.status === 200) {
        setReRunUpdate(null);
        setRefreshUpdate(!refreshUpdate);
        setLoading(false);
        toast.success("Booking Cancelled!");
        navigate("/");
      } else {
        toast.error("Failed to updated data");
        setLoading(false);
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunUpdate(values);
        setRefreshUpdate(!refreshUpdate);
      }
    }
  };
  useEffect(() => {
    if (reRunUpdate !== null) updateData(reRunUpdate, updateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUpdate]);
  const deleteData = async (req, id) => {
    try {
      const updateRes = await BOOKING.DELETE(req, id, token);
      if (updateRes.status === 200) {
        setRefreshDelete(!refreshDelete);
        setReRunData(null);
        toast.success("Booking Deleted");
        navigate("/booking");
      } else {
        toast.error("Failed to delete data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(req);
        setRefreshDelete(!refreshDelete);
      }
    }
  };
  //delete
  useEffect(() => {
    if (reRunData !== null) deleteData(reRunData, updateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDelete]);

  const handleCancelBed = async (item) => {
    const confirmed = window.confirm(
      "You are about to remove this bed! Are you sure about this?"
    );
    if (confirmed) {
      cancelBed(item);
    }
  };
  const handleCancelAme = async (item) => {
    const confirmed = window.confirm(
      "You are about to remove this amenity! Are you sure about this?"
    );
    if (confirmed) {
      cancelAme(item);
    }
  };

  const cancelAme = (item) => {
    const userBookings = oldBookings.filter((element) => {
      return element.buyerId === user._id;
    });
    userBookings.map(async (element) => {
      await element.amenities.map((e) => {
        if (e.itemId === item._id) {
          const indexToRemove = element.amenities.indexOf(e);
          element.amenities.splice(indexToRemove, 1);

          var req = {
            startDate: element.startDate,
            endDate: element.endDate,
            startTime: element.startTime,
            endTime: element.endTime,
            buyerId: element.buyerId,
            buyerName: element.buyerName,
            bed: element.bed,
            amenities: element.amenities,
            isPurged: element.isPurged,
          };
          if (element.amenities.length === 0 && element.bed.length === 0) {
            deleteData(req, element._id);
          } else {
            updateData(req, element._id);
          }
          setUpdateId(element._id);
        }
      });
    });
  };
  const cancelBed = (item) => {
    const userBookings = oldBookings.filter((element) => {
      return element.buyerId === user._id;
    });
    userBookings.map(async (element) => {
      await element.bed.map((e) => {
        if (e.bedId === item._id) {
          const indexToRemove = element.bed.indexOf(e);
          element.bed.splice(indexToRemove, 1);

          var req = {
            startDate: element.startDate,
            endDate: element.endDate,
            startTime: element.startTime,
            endTime: element.endTime,
            buyerId: element.buyerId,
            buyerName: element.buyerName,
            bed: element.bed,
            amenities: element.amenities,
            isPurged: element.isPurged,
          };
          setUpdateId(element._id);
          if (element.amenities.length === 0 && element.bed.length === 0) {
            deleteData(req, element._id);
          } else {
            updateData(req, element._id);
          }
        }
      });
    });
  };
  const today = formatDate(new Date());
  const getNextDate = (date) => {
    const currentDate = new Date(date);
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1); // Adding 1 to the current date
    return nextDate;
  };
  const handleTimeChange = (newValue) => {
    const adjustedTime = dayjs(newValue).minute(0).second(0);
    const formattedTime = convertToTimeFormat(adjustedTime);
    if (formattedTime === "12:00 AM") {
      const endDat = getNextDate(data.startDate);
      setData({
        ...data,
        endDate: formatDate(endDat),
      });
      data.endDate = formatDate(endDat);
    } else {
      setData({
        ...data,
        endDate: data.startDate,
      });
      data.endDate = data.startDate;
    }
    setData({
      ...data,
      startTime: formattedTime,
      endTime: getNextHourTime(formattedTime),
    });
  };
  const convertToTimeFormat = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedMinutes = minutes < 10 ? "00" : "00";
    const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;
    return formattedTime;
  };

  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the sensitivity as needed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className="w-full h-auto">
      <div className="block sm:hidden w-auto h-auto flex flex-col lg:flex-row gap-2 xl:gap-0 items-center justify-between mb-10">
        <div className="w-auto h-full flex border-2 border-gray rounded-lg">
          <button
            onClick={handleStartDate}
            className="flex px-5 py-3  border-r-2 border-gray relative"
          >
            <div className="flex items-center justify-center">
              <div className="w-auto h-auto mr-2.5">
                <img
                  className="w-full h-full object-cover"
                  src={CAL}
                  alt="cal"
                />
              </div>
              <div className="font-semibold text-start">
                <p className="text-[10px] sm:text-sm">CHECK IN</p>
                <p className="text-[12px] sm:text-xs">{formatDateString(data.startDate)}</p>
              </div>
            </div>
            <input
              type="date"
              ref={startDateRef}
              min={today}
              onChange={(e) => {
                setData({
                  ...data,
                  startDate: e.target.value,
                });
                data.startDate = e.target.value;
                if (data.startTime === "12:00 AM") {
                  const endDat = getNextDate(e.target.value);
                  setData({
                    ...data,
                    endDate: formatDate(endDat),
                  });
                  data.endDate = formatDate(endDat);
                } else {
                  setData({
                    ...data,
                    endDate: e.target.value,
                  });
                  data.endDate = e.target.value;
                }
              }}
              className="opacity-0 absolute bg-red h-full w-full top-0 left-0" // Hide the input
            />
          </button>
          <button
            // onClick={handleEndDate}
            className="flex px-5 py-3 relative "
          >
            <div className="flex items-center justify-center">
              <div className="w-auto h-auto mr-2.5">
                <img
                  className="w-full h-full object-cover"
                  src={CAL}
                  alt="cal"
                />
              </div>
              <div className="font-semibold text-start">
                <p className="text-[10px] sm:text-sm">CHECK OUT</p>
                <p className="text-[12px] sm:text-xs">{formatDateString(data.endDate)}</p>
              </div>
            </div>
            {/* <input
              type="date"
              ref={endDateRef}
              onChange={(e) => {
                setData({
                  ...data,
                  endDate: e.target.value,
                });
              }}
              className="opacity-0 absolute bg-red h-full w-full top-0 left-0" // Hide the input
            /> */}
          </button>
        </div>

        <div className="w-75 h-full flex justify-between">
          <button className="py-3.5 w-auto h-auto relative cursor-pointer">
            <div className="flex items-center justify-center ">
              <div className="w-auto h-auto mr-2.5">
                <img
                  className="w-full h-full object-cover"
                  src={CLK}
                  alt="clock"
                />
              </div>
              <div className="text-[12px] sm:text-sm font-semibold">
                <p>{data.startTime}</p>
              </div>
            </div>
            <div className="opacity-0 absolute top-0 w-full left-0">
              <MobileTimePicker
                className="  h-auto w-full bg-green" // Hide the input
                value={selectedTime}
                onChange={handleTimeChange}
                views={["hours"]} // Display only the hour view
              />
            </div>
          </button>

          <button
            // onClick={handleEndTime}
            className=" py-3.5  w-auto h-auto relative"
          >
            <div className="flex items-center justify-center">
              <div className="w-auto h-auto mr-2.5">
                <img
                  className="w-full h-full object-cover"
                  src={CLK}
                  alt="clock"
                />
              </div>
              <div className="text-[12px] sm:text-sm font-semibold">
                <p>{data.endTime}</p>
              </div>
            </div>
            <input
              type="time"
              ref={endTimeRef}
              step="1800" // 1800 seconds = 30 minutes
              onChange={(e) => {
                setData({
                  ...data,
                  endTime: convertTo12HourFormat(e.target.value),
                });
              }}
              className="opacity-0 absolute bg-red h-full w-full top-0 left-0" // Hide the input
            />
          </button>
        </div>
      </div>

      <div className="hidden w-full h-auto sm:flex flex-col sm:flex-row gap-2 xl:gap-0 items-center justify-between mb-10">
        <div className="w-auto h-full flex border-2 border-gray rounded-lg">
          <button
            onClick={handleStartDate}
            className="flex px-4 lg:px-8 py-3  border-r-2 border-gray relative"
          >
            <div className="flex items-center justify-center">
              <div className="w-auto h-auto mr-2.5">
                <img
                  className="w-full h-full object-cover"
                  src={CAL}
                  alt="cal"
                />
              </div>
              <div className="font-semibold text-start">
                <p className="text-[10px] md:text-xs">CHECK IN</p>
                <p className="text-[12px] md:text-sm">{formatDateString(data.startDate)}</p>
              </div>
            </div>
            <input
              type="date"
              ref={startDateRef}
              min={today}
              onChange={(e) => {
                setData({
                  ...data,
                  startDate: e.target.value,
                });
                data.startDate = e.target.value;
                if (data.startTime === "12:00 AM") {
                  const endDat = getNextDate(e.target.value);
                  setData({
                    ...data,
                    endDate: formatDate(endDat),
                  });
                  data.endDate = formatDate(endDat);
                } else {
                  setData({
                    ...data,
                    endDate: e.target.value,
                  });
                  data.endDate = e.target.value;
                }
              }}
              className="opacity-0 absolute bg-red h-full w-full top-0 left-0" // Hide the input
            />
          </button>

          <button
            // onClick={handleStartTime}
            className="px-7 lg:px-10 py-3.5 w-auto h-auto relative cursor-pointer"
          >
            <div className="flex items-center justify-center ">
              <div className="w-auto h-auto mr-2.5">
                <img
                  className="w-full h-full object-cover"
                  src={CLK}
                  alt="clock"
                />
              </div>
              <div className="text-[12px] md:text-sm font-semibold">
                <p>{data.startTime}</p>
              </div>
            </div>
 
            <div className="opacity-0 absolute top-0 w-full left-0">
              <MobileTimePicker
                className="  h-auto w-full bg-green" // Hide the input
                value={selectedTime}
                onChange={handleTimeChange}
                views={["hours"]} // Display only the hour view
              />
            </div>
          </button>
        </div>

        <div className="w-auto h-full flex border-2 border-gray rounded-lg">
          <button
            // onClick={handleEndDate}
            className="flex  px-4 lg:px-8 py-3  border-r-2 border-gray relative"
          >
            <div className="flex items-center justify-center">
              <div className="w-auto h-auto mr-2.5">
                <img
                  className="w-full h-full object-cover"
                  src={CAL}
                  alt="cal"
                />
              </div>
              <div className="font-semibold text-start">
                <p className="text-[10px] md:text-xs">CHECK OUT</p>
                <p className="text-[12px] md:text-sm">{formatDateString(data.endDate)}</p>
              </div>
            </div>
            {/* <input
              type="date"
              ref={endDateRef}
              onChange={(e) => {
                setData({
                  ...data,
                  endDate: e.target.value,
                });
              }}
              className="opacity-0 absolute bg-red h-full w-full top-0 left-0" // Hide the input
            /> */}
          </button>
          <button
            // onClick={handleEndTime}
            className=" px-7 lg:px-10 py-3.5  w-auto h-auto relative"
          >
            <div className="flex items-center justify-center">
              <div className="w-auto h-auto mr-2.5">
                <img
                  className="w-full h-full object-cover"
                  src={CLK}
                  alt="clock"
                />
              </div>
              <div className="text-[12px] md:text-sm font-semibold">
                <p>{data.endTime}</p>
              </div>
            </div>
            <input
              type="time"
              ref={endTimeRef}
              step="1800" // 1800 seconds = 30 minutes
              onChange={(e) => {
                setData({
                  ...data,
                  endTime: convertTo12HourFormat(e.target.value),
                });
              }}
              className="opacity-0 absolute bg-red h-full w-full top-0 left-0" // Hide the input
            />
          </button>
        </div>
      </div>

      <div className="w-full h-full mb-10">
        <div className="flex items-center justify-center mb-5">
          <p className="text-lg font-bold font-muli">Beds</p>
          <hr className="w-full ml-5 border-[#E0E0E0]" />
        </div>
        <div
          ref={containerRef}
          className="overflow-x-auto no-scrollbar"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <div className="flex items-center justify-start w-full">
            <div className="flex flex-row space-x-5  h-full">
              {beds.map((item, index) => (
                <div className="bg-ash relative border-[1px] border-semiash p-2 rounded-md w-[160px] h-auto flex items-center justify-start flex-col">
                  {checkForBooking(item).status === "Block" && (
                    <>
                      {checkForBooking(item).data.includes(user.fullName) && (
                        <div className="absolute rotate-[-30deg] left-0 top-4 w-[50%]">
                          <img src={IMG.BKD} alt="" />
                        </div>
                      )}
                    </>
                  )}

                  <div className="w-auto h-auto pt-8">
                    <img className="object-cover" src={IMG.BED} alt="" />
                  </div>

                  {checkForBooking(item).status === "Block" && (
                    <>
                      {checkForBooking(item).data.includes(user.fullName) ? (
                        <>
                          <div className="flex justify-center items-center ">
                            <p className="text-lg font-bold font-muli mb-5 mt-4">
                              {item.title}
                            </p>
                          </div>
                          <button
                            onClick={() => {
                              handleCancelBed(item);
                            }}
                            className="border-green font-semibold border-[1px] rounded-md text-green w-full py-0.5"
                          >
                            CANCEL
                          </button>
                        </>
                      ) : (
                        <div className="flex justify-center items-center ">
                          <p className="text-lg font-bold font-muli mb-5 mt-4">
                            {item.title}
                          </p>
                          <div className="group mb-1 ml-2">
                            <img
                              className="w-auto h-auto object-cover cursor-pointer"
                              src={IMG.I}
                              alt=""
                            />
                            <div className="absolute bottom-[0%] left-0 w-full text-center scale-0 rounded bg-black p-2 text-xs text-white group-hover:scale-100">
                              <p className="font-semibold text-sm">
                                Booked Users
                              </p>
                              {checkForBooking(item).data.map((e) => (
                                <p className="italic">{e}</p>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {checkForBooking(item).status === "Add" && (
                    <>
                      <div className="flex justify-center items-center ">
                        <p className="text-lg font-bold font-muli mb-5 mt-4">
                          {item.title}
                        </p>
                      </div>
                      <button
                        onClick={() => {
                          handleAddBeds(item, index);
                        }}
                        className="border-primary font-semibold border-[1px] rounded-md text-primary w-full py-0.5"
                      >
                        ADD
                      </button>
                    </>
                  )}
                  {checkForBooking(item).status === "Remove" && (
                    <>
                      <div className="flex justify-center items-center ">
                        <p className="text-lg font-bold font-muli mb-5 mt-4">
                          {item.title}
                        </p>
                      </div>
                      <button
                        onClick={() => {
                          handleRemoveBeds(item, index);
                        }}
                        className="border-black font-semibold border-[1px] rounded-md text-black w-full py-0.5"
                      >
                        REMOVE
                      </button>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="w-full h-auto md:grid gap-5 grid-cols-3 xl:grid-cols-5">
          {beds.map((item, index) => (
            <div className="bg-ash relative border-[1px] border-semiash p-2 rounded-md w-full h-auto flex items-center justify-start flex-col">
              {checkForBooking(item).status === "Block" && (
                <>
                  {checkForBooking(item).data.includes(user.fullName) && (
                    <div className="absolute rotate-[-30deg] left-0 top-4 w-[50%]">
                      <img src={IMG.BKD} alt="" />
                    </div>
                  )}
                </>
              )}

              <div className="w-auto h-auto pt-8">
                <img className="object-cover" src={IMG.BED} alt="" />
              </div>

              {checkForBooking(item).status === "Block" && (
                <>
                  {checkForBooking(item).data.includes(user.fullName) ? (
                    <>
                      <div className="flex justify-center items-center ">
                        <p className="text-lg font-bold font-muli mb-5 mt-4">
                          {item.title}
                        </p>
                      </div>
                      <button
                        onClick={() => {
                          handleCancelBed(item);
                        }}
                        className="border-green font-semibold border-[1px] rounded-md text-green w-full py-0.5"
                      >
                        CANCEL
                      </button>
                    </>
                  ) : (
                    <div className="flex justify-center items-center ">
                      <p className="text-lg font-bold font-muli mb-5 mt-4">
                        {item.title}
                      </p>
                      <div className="group mb-1 ml-2">
                        <img
                          className="w-auto h-auto object-cover cursor-pointer"
                          src={IMG.I}
                          alt=""
                        />
                        <div className="absolute bottom-[0%] left-0 w-full text-center scale-0 rounded bg-black p-2 text-xs text-white group-hover:scale-100">
                          <p className="font-semibold text-sm">Booked Users</p>
                          {checkForBooking(item).data.map((e) => (
                            <p className="italic">{e}</p>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {checkForBooking(item).status === "Add" && (
                <>
                  <div className="flex justify-center items-center ">
                    <p className="text-lg font-bold font-muli mb-5 mt-4">
                      {item.title}
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      handleAddBeds(item, index);
                    }}
                    className="border-primary font-semibold border-[1px] rounded-md text-primary w-full py-0.5"
                  >
                    ADD
                  </button>
                </>
              )}
              {checkForBooking(item).status === "Remove" && (
                <>
                  <div className="flex justify-center items-center ">
                    <p className="text-lg font-bold font-muli mb-5 mt-4">
                      {item.title}
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      handleRemoveBeds(item, index);
                    }}
                    className="border-black font-semibold border-[1px] rounded-md text-black w-full py-0.5"
                  >
                    REMOVE
                  </button>
                </>
              )}
            </div>
          ))}
        </div> */}
      </div>

      <div className="w-full h-full mb-10">
        <div className="flex items-center justify-center mb-5">
          <p className="text-lg font-bold font-muli">Amenities</p>
          <hr className="w-full ml-5 border-[#E0E0E0]" />
        </div>
        <div
          ref={containerRef}
          className="overflow-x-auto no-scrollbar"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <div className="flex items-center justify-start w-full">
            <div className="flex flex-row space-x-5  h-full">
              {amenities.map((item, index) => (
                <div className="bg-ash relative border-[1px] pb-2 border-semiash rounded-lg w-[160px] h-auto flex items-center justify-start flex-col">
                  {checkForAmenities(item).status === "Block" && (
                    <>
                      {checkForAmenities(item).data.includes(user.fullName) && (
                        <div className="absolute rotate-[-30deg] left-0 top-4 w-[50%]">
                          <img src={IMG.BKD} alt="" />
                        </div>
                      )}
                    </>
                  )}
                  <div className="w-full h-auto ">
                    <img
                      className="object-cover w-full rounded-t-lg h-[150px]"
                      src={getImgUrl(item.image)}
                      alt="amenities"
                    />
                  </div>

                  {checkForAmenities(item).status === "Block" && (
                    <>
                      {checkForAmenities(item).data.includes(user.fullName) ? (
                        <div className="px-2 w-full">
                          <div className="w-full flex justify-center items-center">
                            <p className="text-lg font-medium font-muli my-4 text-center">
                              {item.title}
                            </p>
                          </div>
                          <button
                            onClick={() => {
                              handleCancelAme(item);
                            }}
                            className="border-green font-semibold border-[1px] rounded-md text-green w-full py-0.5"
                          >
                            CANCEL
                          </button>
                        </div>
                      ) : (
                        <div className="w-full px-2 flex justify-center items-center">
                          <p className="text-lg font-medium font-muli my-4 text-center">
                            {item.title}
                          </p>

                          <div className="group mb-1 ml-2">
                            <img
                              className="w-auto h-auto object-cover cursor-pointer"
                              src={IMG.I}
                              alt=""
                            />
                            <div className="absolute bottom-[0%] left-0 w-full text-center scale-0 rounded bg-black p-2 text-xs text-white group-hover:scale-100">
                              <p className="font-semibold text-sm">
                                Booked Users
                              </p>
                              {checkForAmenities(item).data.map((e) => (
                                <p className="italic">{e}</p>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <div className="w-full px-2">
                    {checkForAmenities(item).status === "Add" && (
                      <>
                        <div className="w-full px-2 flex justify-center items-center">
                          <p className="text-lg font-medium font-muli my-4 text-center">
                            {item.title}
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            handleAddAmenities(item, index);
                          }}
                          className="border-primary font-semibold border-[1px] rounded-md text-primary w-full py-0.5"
                        >
                          ADD
                        </button>
                      </>
                    )}
                    {checkForAmenities(item).status === "Remove" && (
                      <>
                        <div className="w-full px-2 flex justify-center items-center">
                          <p className="text-lg font-medium font-muli my-4 text-center">
                            {item.title}
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            handleRemoveAmenities(item, index);
                          }}
                          className="border-black font-semibold border-[1px] rounded-md text-black w-full py-0.5"
                        >
                          REMOVE
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="w-full h-auto grid gap-5 grid-cols-3 xl:grid-cols-5 ">
          {amenities.map((item, index) => (
            <div className="bg-ash relative border-[1px] pb-2 border-semiash rounded-lg w-full h-auto flex items-center justify-start flex-col">
              {checkForAmenities(item).status === "Block" && (
                <>
                  {checkForAmenities(item).data.includes(user.fullName) && (
                    <div className="absolute rotate-[-30deg] left-0 top-4 w-[50%]">
                      <img src={IMG.BKD} alt="" />
                    </div>
                  )}
                </>
              )}
              <div className="w-full h-auto ">
                <img
                  className="object-cover w-full rounded-t-lg h-[150px]"
                  src={getImgUrl(item.image)}
                  alt="amenities"
                />
              </div>

              {checkForAmenities(item).status === "Block" && (
                <>
                  {checkForAmenities(item).data.includes(user.fullName) ? (
                    <div className="px-2 w-full">
                      <div className="w-full flex justify-center items-center">
                        <p className="text-lg font-medium font-muli my-4 text-center">
                          {item.title}
                        </p>
                      </div>
                      <button
                        onClick={() => {
                          handleCancelAme(item);
                        }}
                        className="border-green font-semibold border-[1px] rounded-md text-green w-full py-0.5"
                      >
                        CANCEL
                      </button>
                    </div>
                  ) : (
                    <div className="w-full px-2 flex justify-center items-center">
                      <p className="text-lg font-medium font-muli my-4 text-center">
                        {item.title}
                      </p>

                      <div className="group mb-1 ml-2">
                        <img
                          className="w-auto h-auto object-cover cursor-pointer"
                          src={IMG.I}
                          alt=""
                        />
                        <div className="absolute bottom-[0%] left-0 w-full text-center scale-0 rounded bg-black p-2 text-xs text-white group-hover:scale-100">
                          <p className="font-semibold text-sm">Booked Users</p>
                          {checkForAmenities(item).data.map((e) => (
                            <p className="italic">{e}</p>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="w-full px-2">
                {checkForAmenities(item).status === "Add" && (
                  <>
                    <div className="w-full px-2 flex justify-center items-center">
                      <p className="text-lg font-medium font-muli my-4 text-center">
                        {item.title}
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        handleAddAmenities(item, index);
                      }}
                      className="border-primary font-semibold border-[1px] rounded-md text-primary w-full py-0.5"
                    >
                      ADD
                    </button>
                  </>
                )}
                {checkForAmenities(item).status === "Remove" && (
                  <>
                    <div className="w-full px-2 flex justify-center items-center">
                      <p className="text-lg font-medium font-muli my-4 text-center">
                        {item.title}
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        handleRemoveAmenities(item, index);
                      }}
                      className="border-black font-semibold border-[1px] rounded-md text-black w-full py-0.5"
                    >
                      REMOVE
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div> */}
      </div>

      <div className="bg-gray w-full h-[1px]" />

      <div className="flex flex-col md:flex-row justify-between items-center pt-5">
        {added.length > 0 ? (
          <p className="text-black font-bold mb-5 md:mb-0">
            Booked :{" "}
            {added.map((data) => (
              <span>{data}, </span>
            ))}{" "}
          </p>
        ) : (
          <p className="text-black font-bold"></p>
        )}
        <button
          className="bg-primary text-white font-semibold px-12 py-3 rounded-md text-md w-full md:w-auto"
          onClick={() => {
            handleAddBooking();
          }}
        >
          {loading ? (
            <div className="flex h-auto items-center justify-center ">
              <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
            </div>
          ) : (
            "RESERVE"
          )}
        </button>
      </div>
    </div>
  );
}

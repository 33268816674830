import React, { useRef, useState } from "react";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";

export default function BedView() {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the sensitivity as needed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  return (
    <div
      ref={containerRef}
      className="overflow-x-auto"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div className="flex items-center justify-center">
        <div className="flex flex-row space-x-4">
          <div className="flex-none w-[200px] h-48 bg-gray rounded-lg"></div>
          <div className="flex-none w-[200px] h-48 bg-gray rounded-lg"></div>
          <div className="flex-none w-[200px] h-48 bg-gray rounded-lg"></div>
          <div className="flex-none w-[200px] h-48 bg-gray rounded-lg"></div>
          <div className="flex-none w-[200px] h-48 bg-gray rounded-lg"></div>
          <div className="flex-none w-[200px] h-48 bg-gray rounded-lg"></div>
        </div>
      </div>
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { TokenContext } from "../../../utils/TokenContext";
import { UseAuth } from "../../../utils/UseAuth";
import { USER } from "../../../api/user";
export default function UserAdd() {
  const { checkAccessTokenValidity } = UseAuth();
  const navigate = useNavigate();
  const { token } = useContext(TokenContext);
  const [refresh, setRefresh] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data] = useState({
    name: "",
    password: "",
    fullName: "",
    email: "",
    tokenId: "",
    isPurged: false,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    fullName: Yup.string().required("Required"),
    // email: Yup.string().required("Required"),
  });

  const addData = async (values) => {
    setLoading(true);
    values.password = values.name + "@" + 2024;
    console.log("values", values);
    console.log("token", token);
    try {
      const res = await USER.ADD(values, token);
      setLoading(false);
      setReRunData(null);
      if (res.status === 200) {
        toast.success("Created New User");
        navigate("/user");
      } else {
        toast.error("Failed to create new user");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefresh(!refresh);
      }
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    addData(values);
  };

  useEffect(() => {
    if (reRunData !== null) addData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <div>
      <Breadcrumbs pageName="Add User" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Add New User
          </h3>
        </div>
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form className="grid grid-cols-1 sm:grid-cols-2">
            <div className="flex flex-col ">
              <div className="px-6.5 py-2">
                <label className="mb-2.5 block text-black dark:text-white">
                  Login Name <span className="text-meta-1">*</span>
                </label>
                <Field
                  placeholder="Enter the login name"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  name="name"
                />
                <ErrorMessage
                  component="a"
                  className="text-danger"
                  name="name"
                />
              </div>
            </div>
            <div className="flex flex-col h-auto">
              <div className="px-6.5 py-2 h-auto">
                <label className="mb-2.5 block text-black dark:text-white">
                  Full Name <span className="text-meta-1">*</span>
                </label>
                <Field
                  placeholder="Enter the full name"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  name="fullName"
                />
                <ErrorMessage
                  component="a"
                  className="text-danger"
                  name="fullName"
                />
              </div>
            </div>
            <div className="flex flex-col h-auto">
              <div className="px-6.5 py-2 h-auto">
                <label className="mb-2.5 block text-black dark:text-white">
                  Email 
                </label>
                <Field
                  placeholder="Enter user email"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  name="email"
                />
                <ErrorMessage
                  component="a"
                  className="text-danger"
                  name="email"
                />
              </div>
            </div>

            <div className="p-6.5">
              <button
                type="submit"
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray mt-4"
              >
                {loading ? (
                  <div className="flex h-auto items-center justify-center ">
                    <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                  </div>
                ) : (
                  "Add User"
                )}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

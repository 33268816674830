export const IMG = {
  BG: require("./bg.png"),
  CAVE: require("./cave.png"),
  DIVE: require("./dive.png"),
  SKI: require("./ski.png"),
  BED: require("./bed.png"),
  I: require("./i.png"),
  CAL: require("./cal.png"),
  CLK: require("./clock.png"),
  BLK: require("./block.png"),
  BKD: require("./booked.png"),
  ACT: require("./act.png"),
  SVC: require("./svc.png"),
};

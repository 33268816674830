import React, { useState } from "react";
import { IMG } from "../../../assets";
import BedBooking from "./BedBooking";
import ServiceAdd from "./ServiceAdd";

export default function Home() {
  const [showSvc, setShowSvc] = useState(false);
  return (
    <>
      <div className="h-full w-full min-h-[90vh] flex md:hidden flex-col justify-center items-start px-2 pt-2">
        <img
          src={IMG.BG}
          alt="bg"
          className="w-full object-cover rounded-t-xl"
        />
        <div className="w-full h-full relative top-[-20px] flex justify-center items-center md:px-80">
          <DataDiv setShowSvc={setShowSvc} showSvc={showSvc} />
        </div>
      </div>

      <div
        className="h-full w-full min-h-[90vh] relative bg-event py-10 hidden md:flex justify-center items-center "
        style={{
          backgroundImage: `url(${IMG.BG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="w-full h-full top-0 flex justify-center items-center py-2 md:px-80 ">
          <DataDiv setShowSvc={setShowSvc} showSvc={showSvc} />
        </div>
      </div>
    </>
  );
}

const DataDiv = ({ setShowSvc, showSvc }) => {
  return (
    <div className="w-full  xl:max-w-[1000px] min-w-screen md:min-w-[750px] lg:min-w-[900px] xl:min-w-[1000px] bg-white rounded-3xl shadow-none md:shadow-xl px-5 lg:px-15 py-8 ">
      <div className="w-full">
        <p className="text-2xl md:text-3xl font-semibold font-muli">
          Chaletfatma Beach House
        </p>
        <div className="flex flex-row gap-5  w-full mb-5 border-b-2  border-gray mt-5">
          <button
            onClick={() => {
              setShowSvc(false);
            }}
            className={`${
              !showSvc ? "border-b-2  text-primary" : "text-gray"
            }  px-1 font-muli text-sm md:text-lg`}
          >
            Beds & Other Amenities
          </button>
          <button
            onClick={() => {
              setShowSvc(true);
            }}
            className={`${
              showSvc ? "border-b-2  text-primary" : "text-gray"
            }  px-1 font-muli text-sm md:text-lg`}
          >
            Services
          </button>
        </div>
        <div>{!showSvc ? <BedBooking /> : <ServiceAdd />}</div>
      </div>
    </div>
  );
};

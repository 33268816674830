import React from "react";

export default function NoPage() {
  return (
    <div className="h-full w-full flex justify-center">
      <img
        src="https://miro.medium.com/v2/0*Vio_q5nMzzD4DkWO.JPG"
        alt="404"
        className="w-full object-contain"
      />
    </div>
  );
}

import React, { useContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/header/Header";
import Dashboard from "../pages/admin/dashboard/Dashboard";
import EmpList from "../pages/admin/employee/EmpList";
import EmpAdd from "../pages/admin/employee/EmpAdd";
import EmpUpdate from "../pages/admin/employee/EmpUpdate";
import AdminSideBar from "../components/sidebar/AdminSideBar";
import EmpView from "../pages/admin/employee/EmpView";
import { AuthContext } from "../utils/AuthContext";
import ProfileScreen from "../pages/admin/profile/ProfileScreen";
import NotAllowed from "../pages/unauth/NotAllowed";
import NoPage from "../pages/unauth/NoPage";
import UserList from "../pages/admin/user/UserList";
import UserAdd from "../pages/admin/user/UserAdd";
import UserUpdate from "../pages/admin/user/UserUpdate";
import UserView from "../pages/admin/user/UserView";
import SessionList from "../pages/admin/session/SessionList";
import SessionDetails from "../pages/admin/session/SessionDetails";
import AllBed from "../pages/admin/bed/AllBed";
import ServiceList from "../pages/admin/service/ServiceList";
import ListBooking from "../pages/admin/booking/ListBooking";
import AddBooking from "../pages/admin/booking/AddBooking";
import ViewBookings from "../pages/admin/booking/ViewBookings";
import SvcBookingView from "../pages/admin/svcBooking/SvcBookingView";
import SvcBookingList from "../pages/admin/svcBooking/SvcBookingList";
import AmenityAdd from "../pages/admin/amenity/AmenityAdd";

export default function AdminLayout() {
  const { user } = useContext(AuthContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div suppressHydrationWarning={true}>
      <div className="dark:bg-boxdark-2 dark:text-bodydark">
        <div className="flex h-screen overflow-hidden">
          <AdminSideBar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
          <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div>
              <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                <Routes>
                  <Route path="/">
                    <Route index element={<Dashboard />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="profile" element={<ProfileScreen />} />
                    <Route path="admin">
                      <Route
                        index
                        element={user.role === 0 ? <EmpList /> : <NotAllowed />}
                      />
                      <Route
                        path="add"
                        element={user.role === 0 ? <EmpAdd /> : <NotAllowed />}
                      />
                      <Route
                        path="update/:id"
                        element={
                          user.role === 0 ? <EmpUpdate /> : <NotAllowed />
                        }
                      />
                      <Route
                        path="view/:id"
                        element={user.role === 0 ? <EmpView /> : <NotAllowed />}
                      />
                    </Route>
                    <Route path="user">
                      <Route index element={<UserList />} />
                      <Route path="add" element={<UserAdd />} />
                      <Route path="update/:id" element={<UserUpdate />} />
                      <Route path="view/:id" element={<UserView />} />
                    </Route>
                    <Route path="session">
                      <Route index element={<SessionList />} />
                      <Route path="add" element={<UserAdd />} />
                      <Route path="date/:date" element={<SessionDetails />} />
                      <Route path="update/:id" element={<UserUpdate />} />
                      <Route path="view/:id" element={<UserView />} />
                    </Route>
                    <Route path="booking">
                      <Route index element={<ListBooking />} />
                      <Route path="add" element={<AddBooking />} />
                      <Route path="view/:id" element={<ViewBookings />} />
                    </Route>
                    <Route path="svc-booking">
                      <Route index element={<SvcBookingList />} />
                      <Route path="view/:id" element={<SvcBookingView />} />
                    </Route>

                    <Route path="bed" element={<AllBed />} />
                    <Route path="amenity" element={<AmenityAdd />} />
                    <Route path="service" element={<ServiceList />} />
                    <Route path="*" element={<NoPage />} />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { SVC } from "../../../api/service";
import { UseAuth } from "../../../utils/UseAuth";
import { TokenContext } from "../../../utils/TokenContext";
import { SVC_BOOKING } from "../../../api/svcBooking";
import { toast } from "react-toastify";
import { AuthContext } from "../../../utils/AuthContext";
import { formatDateString } from "../../../utils/FormatDateString";
import { IMG } from "../../../assets";

export default function ServiceAdd() {
  const { user } = useContext(AuthContext);
  const { token } = useContext(TokenContext);
  const [validate, setValidate] = useState(false);
  const { checkAccessTokenValidity } = UseAuth();
  const [reRunData, setReRunData] = useState(null);
  const [refreshAdd, setRefreshAdd] = useState(false);
  const [options, setOtions] = useState([]);
  const [svcValue, setSvcValue] = useState({});
  const [data, setData] = useState({
    date: formatDate(new Date()),
    title: "",
    note: "",
  });
  console.log("data", data);
  const onChangeSelect = (e) => {
    setData({
      ...data,
      title: e.value,
    });
    data.title = e.value;
    setSvcValue({ value: e.value, label: e.value });
  };
  const [loading, setLoading] = useState(false);

  const clearData = () => {
    setData({
      ...data,
      title: "",
      note: "",
    });
    setLoading(false);
    setValidate(false);
    setRefreshAdd(false);
    setReRunData(null);
    setSvcValue(options[0]);
  };
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const addData = async (values) => {
    console.log(values, "values");
    setLoading(true);
    try {
      const res = await SVC_BOOKING.ADD(values, token);
      setReRunData(null);
      clearData();
      if (res.status === 200 || res.status === 201) {
        if (res.status === 200) {
          toast.success("Submited your service request");
          setRefreshAdd(!refreshAdd);
          clearData();
        }
      } else {
        toast.error("Failed to create data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response && error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefreshAdd(!refreshAdd);
      }
    }
  };
  const handleAddService = () => {
    if (data.note !== "") {
      setValidate(false);
      setLoading(true);
      var req = {
        date: data.date,
        title: data.title,
        note: data.note,
        buyerId: user._id,
        buyerName: user.fullName,
        isRead: false,
        isFinished: false,
        isPurged: false,
      };
      addData(req);
    } else {
      setValidate(true);
    }
  };
  const getSvcList = async () => {
    try {
      const res = await SVC.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch user information");
      }
      const array = [];
      res.data.map((e) => {
        var req = { value: e.title, label: e.title };
        array.push(req);
      });
      setOtions(array);
      setSvcValue(array[0]);
      setData({
        ...data,
        title: array[0].value,
      });
      data.title = array[0].value;
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  useEffect(() => {
    getSvcList();
  }, []);
  useEffect(() => {
    if (reRunData !== null) addData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAdd]);
  const endDateRef = useRef(null);
  const handleEndDate = () => {
    if (endDateRef.current && typeof endDateRef.current.showPicker === 'function') {
      try {
        endDateRef.current.showPicker();
      } catch (error) {
        console.error("Error showing date picker: ", error);
      }
    } else {
      // Fallback: Trigger a click on the date input to show the date picker in browsers that don't support showPicker
      endDateRef.current.click();
    }
  };
  return (
    <div className="w-full">
      <div className="bg-ash rounded-lg p-5 border-[1px] border-gray">
        <div className="flex flex-col md:flex-row justify-between items-center pb-5">
          <div className="w-full md:w-auto h-full flex border-2 border-gray rounded-lg mb-5 md:mb-0">
            <button
              onClick={handleEndDate}
              className="flex pl-2 pr-15 py-1 border-gray relative cursor-pointer"
            >
              <div className="flex items-center justify-center">
                <div className="w-auto h-auto mr-2.5">
                  <img
                    className=" h-full object-cover"
                    src={IMG.CAL}
                    alt="cal"
                  />
                </div>
                <div className="font-semibold text-start">
                  <p className="text-[10px]">CHOOSE DATE</p>
                  <p className="text-sm">{formatDateString(data.date)}</p>
                </div>
              </div>
              <input
                type="date"
                ref={endDateRef}
                min={formatDate(new Date())}
                onChange={(e) => {
                  setData({
                    ...data,
                    date: e.target.value,
                  });
                }}
                className="opacity-0 absolute bg-red h-full w-full top-0 left-0" // Hide the input
              />
            </button>
          </div>
          <Select
            value={svcValue}
            onChange={onChangeSelect}
            options={options}
            className="w-full md:w-[35%] shadow-md"
          />
        </div>
        <div className="rounded-md bg-[#F5F5F5]">
          <textarea
            value={data.note}
            onChange={(e) => {
              setData({
                ...data,
                note: e.target.value,
              });
              data.note = e.target.value;
            }}
            className="min-h-[300px] w-[100%] resize-none border border-[#E4E4E4] outline-none p-[10px]  rounded-md"
            placeholder="Add instruction"
          ></textarea>
          {data.note === "" && validate && (
            <p className="text-red text-sm mt-1">* Required</p>
          )}
        </div>
      </div>
      <div className="w-full flex justify-end pt-5">
        <button
          className="bg-primary text-white font-semibold px-12 py-3 rounded-md text-md w-full md:w-auto"
          onClick={() => {
            handleAddService();
          }}
        >
          {loading ? (
            <div className="flex h-auto items-center justify-center ">
              <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
            </div>
          ) : (
            "SUBMIT"
          )}
        </button>
      </div>
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { ADMIN } from "../../../api/admin";
import { TokenContext } from "../../../utils/TokenContext";
import Select from "react-select";
import { UseAuth } from "../../../utils/UseAuth";
export default function BrandAdd() {
  const { checkAccessTokenValidity } = UseAuth();
  const navigate = useNavigate();
  const { token } = useContext(TokenContext);
  const [refresh, setRefresh] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data] = useState({
    name: "",
    password: "",
    role: 0,
    tokenId: "",
    isPurged: false,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    role: Yup.number().required("Required"),
  });

  const SelectField = ({ options, form, field, ...props }) => {
    return (
      <Select
        {...props}
        options={options}
        name={field.name}
        value={
          options ? options.find((option) => option.value === field.value) : ""
        }
        onChange={(option) => form.setFieldValue(field.name, option.value)}
        // onBlur={field.onBlur}
      />
    );
  };

  const addData = async (values) => {
    setLoading(true);
    values.password = values.name + "@" + 2024;
    console.log("values", values);
    console.log("token", token);
    try {
      const res = await ADMIN.ADD(values, token);
      setLoading(false);
      setReRunData(null);
      if (res.status === 200) {
        toast.success("Created New Admin");
        navigate("/admin");
      } else {
        toast.error("Failed to create new admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefresh(!refresh);
      }
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    addData(values);
  };

  useEffect(() => {
    if (reRunData !== null) addData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const options = [
    { value: 0, label: "Super Admin" },
    { value: 1, label: "Admin" },
  ];

  return (
    <div>
      <Breadcrumbs pageName="Add Admin" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Add New Admin
          </h3>
        </div>
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form className="grid grid-cols-1 sm:grid-cols-2">
            <div className="flex flex-col ">
              <div className="px-6.5 py-2">
                <label className="mb-2.5 block text-black dark:text-white">
                  User Name <span className="text-meta-1">*</span>
                </label>
                <Field
                  placeholder="Enter the user name"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  name="name"
                />
                <ErrorMessage
                  component="a"
                  className="text-danger"
                  name="name"
                />
              </div>
            </div>
            <div className="flex flex-col h-auto">
              <div className="px-6.5 py-2 h-auto">
                <label className="mb-2.5 block text-black dark:text-white">
                  Status <span className="text-meta-1">*</span>
                </label>
                <Field name="role" component={SelectField} options={options} />
                <ErrorMessage
                  component="a"
                  className="text-danger"
                  name="role"
                />
              </div>
            </div>

            <div className="p-6.5">
              <button
                type="submit"
                className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? (
                  <div className="flex h-auto items-center justify-center ">
                    <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                  </div>
                ) : (
                  "Add Admin"
                )}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

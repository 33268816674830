import React, { useContext, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import UnAuthLayout from "./UnAuthLayout";
import { AuthContext } from "../utils/AuthContext";
import AdminLayout from "./AdminLayout";
import { TokenContext } from "../utils/TokenContext";
import UserLayout from "./UserLayout";

export default function Configuration() {
  const { user, setUser } = useContext(AuthContext);
  const { token, setToken } = useContext(TokenContext);
  console.log("user", user);
  console.log("token", token);
  let asyncValue = {};
  let asyncTokValue = {};
  const readData = async () => {
    try {
      const storedValue = await localStorage.getItem("user");
      const storedTokenValue = await localStorage.getItem("token");
      if (storedValue !== null) {
        asyncValue = JSON.parse(storedValue);
        if (asyncValue !== null) {
          setUser(asyncValue);
        }
      }
      if (storedTokenValue !== null) {
        asyncTokValue = storedTokenValue;
        if (asyncTokValue !== null) {
          setToken(asyncTokValue);
        }
      }
    } catch (e) {
      console.log("Failed to fetch the input from storage", e);
    }
  };
  useEffect(() => {
    readData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BrowserRouter>
      {user === null ? (
        <UnAuthLayout />
      ) : (
        <>{user.role === undefined ? <UserLayout /> : <AdminLayout />}</>
      )}
    </BrowserRouter>
  );
}

import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const BOOKING = {
  ADD(req, token) {
    const url = `${CONSTANT.URL}booking`;
    return API_REQUEST("POST", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  GET_COUNT_TODAY(req) {
    const url = `${CONSTANT.URL}booking/dashboard-data`;
    return API_REQUEST("POST", url, req);
  },
  DELETE(req, id, token) {
    const url = `${CONSTANT.URL}booking/delete/${id}`;
    return API_REQUEST("PUT", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  UPDATE(req, id, token) {
    const url = `${CONSTANT.URL}booking/${id}`;
    return API_REQUEST("PUT", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  SEARCH(req, token) {
    const url = `${CONSTANT.URL}booking/search`;
    return API_REQUEST(
      "POST",
      url,
      req
      // {
      //   Authorization: `Bearer ${token}`,
      // }
    );
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}booking`;
    return API_REQUEST("GET", url);
  },
  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}booking/${id}`;
    return API_REQUEST("GET", url);
  },
  GET_BY_DATE(date) {
    const url = `${CONSTANT.URL}booking/date/${date}`;
    return API_REQUEST("GET", url);
  },
  GET_BY_USER(id) {
    const url = `${CONSTANT.URL}booking/user/${id}`;
    return API_REQUEST("GET", url);
  },
  GET_USER_BOOKING(id) {
    const url = `${CONSTANT.URL}booking/user-booking/${id}`;
    return API_REQUEST("GET", url);
  },
};

import "./App.css";
import { AuthContext } from "./utils/AuthContext";
import { useMemo, useState } from "react";
import { TokenContext } from "./utils/TokenContext";
import Configuration from "./layout/Configuration";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  const [user, setUser] = useState(null);
  const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  const [token, setToken] = useState(null);
  const tokenValue = useMemo(() => ({ token, setToken }), [token, setToken]);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthContext.Provider value={userValue}>
          <TokenContext.Provider value={tokenValue}>
            <Configuration />
          </TokenContext.Provider>
        </AuthContext.Provider>
      </LocalizationProvider>
    </>
  );
}

export default App;

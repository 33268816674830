export const formatDateString = (date) => {
  var dateObj = new Date(date);
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var formattedDate =
    dateObj.getDate() +
    " " +
    monthNames[dateObj.getMonth()] +
    " " +
    dateObj.getFullYear();
  return formattedDate;
};

import { useState } from "react";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { ADMIN } from "../../../api/admin";
export default function EmpView() {
  let { id } = useParams();
  const [data, setData] = useState({
    _id: "",
    name: "",
    password: "",
    role: 0,
    tokenId: "",
    isPurged: false,
  });

  const getEmp = async (id) => {
    try {
      const res = await ADMIN.GET_BY_ID(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch employee information");
      }
      setData({
        ...data,
        _id: res.data[0]._id,
        name: res.data[0].name,
        password: res.data[0].password,
        role: res.data[0].role,
        tokenId: res.data[0].tokenId,
        isPurged: res.data[0].isPurged,
      });

      data._id = res.data[0]._id;
      data.name = res.data[0].name;
      data.password = res.data[0].password;
      data.role = res.data[0].role;
      data.tokenId = res.data[0].tokenId;
      data.isPurged = res.data[0].isPurged;
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  const checkRole = (role) => {
    var rtnVal = "";
    if (role === 0) {
      rtnVal = "Super admin";
    } else if (role === 1) {
      rtnVal = "admin";
    } else if (role === 2) {
      rtnVal = "Booking clerk";
    } else if (role === 3) {
      rtnVal = "Ticket inspector";
    }
    return rtnVal;
  };
  useEffect(() => {
    getEmp(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <div>
      <Breadcrumbs pageName="View Admin Details" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            View Details
          </h3>
        </div>

        <div className="grid grid-cols-1 gap-9 sm:grid-cols-2 mb-5">
          <div className="flex flex-col ">
            <div className="px-6.5 py-2">
              <label className="mb-2.5 block text-black dark:text-white">
                User Name <span className="text-meta-1">*</span>
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {data.name}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-6.5 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Status <span className="text-meta-1">*</span>
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {checkRole(data.role)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { UseAuth } from "../../../utils/UseAuth";
import { useNavigate, useParams } from "react-router-dom";
import { BOOKING } from "../../../api/booking";
import { toast } from "react-toastify";
import { TokenContext } from "../../../utils/TokenContext";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { formatDateString } from "../../../utils/FormatDateString";
import { BED } from "../../../api/bed";
import { IMG } from "../../../assets";

export default function ViewBookings() {
  const { checkAccessTokenValidity } = UseAuth();
  let { id } = useParams();
  const [data, setData] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    buyerId: "",
    buyerName: "",
    bed: [],
    amenities: [],
  });
  const navigate = useNavigate();
  const { token } = useContext(TokenContext);
  const [loading, setLoading] = useState(false);

  const getEmp = async (id) => {
    try {
      const res = await BOOKING.GET_BY_ID(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch employee information");
      }
      setData({
        ...data,
        startDate: res.data[0].startDate,
        endDate: res.data[0].endDate,
        startTime: res.data[0].startTime,
        endTime: res.data[0].endTime,
        buyerId: res.data[0].buyerId,
        buyerName: res.data[0].buyerName,
        bed: res.data[0].bed,
        amenities: res.data[0].amenities,
      });

      data.startDate = res.data[0].startDate;
      data.endDate = res.data[0].endDate;
      data.startTime = res.data[0].startTime;
      data.endTime = res.data[0].endTime;
      data.buyerId = res.data[0].buyerId;
      data.buyerName = res.data[0].buyerName;
      data.bed = res.data[0].bed;
      data.amenities = res.data[0].amenities;
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  const getBedName = async (id) => {
    var rtnVal = "";
    try {
      const res = await BED.GET_BY_ID(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch employee information");
      }
      rtnVal = res.data[0].title;
    } catch (error) {
      console.log("Error loading topics: ", error);
      rtnVal = "Not found!";
    }
    return rtnVal;
  };
  console.log("data", data);
  useEffect(() => {
    getEmp(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [refreshUpdate, setRefreshUpdate] = useState(false);
  const [reRunUpdate, setReRunUpdate] = useState(null);
  const [updateId, setUpdateId] = useState("");
  const updateData = async (values, id) => {
    setLoading(true);
    try {
      const res = await BOOKING.UPDATE(values, id, token);
      if (res.status === 200) {
        setReRunUpdate(null);
        setRefreshUpdate(!refreshUpdate);
        setLoading(false);
        toast.success("Booking Cancelled!");
        // navigate("/booking");
      } else {
        toast.error("Failed to updated data");
        setLoading(false);
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunUpdate(values);
        setRefreshUpdate(!refreshUpdate);
      }
    }
  };
  useEffect(() => {
    if (reRunUpdate !== null) updateData(reRunUpdate, updateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUpdate]);
  const handleCancelBed = async (item) => {
    const confirmed = window.confirm(
      "You are about to reopen this bed! Are you sure about this?"
    );
    if (confirmed) {
      cancelBed(item);
    }
  };
  const handleCancelAme = async (item) => {
    const confirmed = window.confirm(
      "You are about to reopen this amenity! Are you sure about this?"
    );
    if (confirmed) {
      cancelAme(item);
    }
  };
  const cancelAme = (item) => {
    data.amenities.map((e) => {
      if (e.itemId === item.itemId) {
        const indexToRemove = data.amenities.indexOf(e);
        data.amenities.splice(indexToRemove, 1);

        var req = {
          startDate: data.startDate,
          endDate: data.endDate,
          startTime: data.startTime,
          endTime: data.endTime,
          buyerId: data.buyerId,
          buyerName: data.buyerName,
          bed: data.bed,
          amenities: data.amenities,
          isPurged: data.isPurged,
        };
        setUpdateId(id);
        console.log("in")
        if (data.amenities.length === 0 && data.bed.length === 0) {
          deleteData(req, id);
        } else {
          updateData(req, id);
        }
      }
    });
  };
  const cancelBed = (item) => {
    data.bed.map((e) => {
      if (e.bedId === item.bedId) {
        const indexToRemove = data.bed.indexOf(e);
        data.bed.splice(indexToRemove, 1);
        var req = {
          startDate: data.startDate,
          endDate: data.endDate,
          startTime: data.startTime,
          endTime: data.endTime,
          buyerId: data.buyerId,
          buyerName: data.buyerName,
          bed: data.bed,
          amenities: data.amenities,
          isPurged: data.isPurged,
        };
        setUpdateId(id);
        // updateData(req, id);
        if (data.amenities.length === 0 && data.bed.length === 0) {
          deleteData(req, id);
        } else {
          updateData(req, id);
        }
      }
    });
  };
  //delete
  const handleRemoveBooking = async () => {
    const confirmed = window.confirm(
      "You are about to delete this data! Are you sure?"
    );

    if (confirmed) {
      try {
        deleteData(data, id);
      } catch (error) {
        // console.log("Error loading topics: ", error);
      }
    }
  };
  const [reRunData, setReRunData] = useState(null);
  const [refreshDelete, setRefreshDelete] = useState(false);
  const deleteData = async (req, id) => {
    try {
      const updateRes = await BOOKING.DELETE(req, id, token);
      if (updateRes.status === 200) {
        setRefreshDelete(!refreshDelete);
        setReRunData(null);
        toast.success("Booking Deleted");
        navigate("/booking");
      } else {
        toast.error("Failed to delete data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(req);
        setRefreshDelete(!refreshDelete);
      }
    }
  };
  //delete
  useEffect(() => {
    if (reRunData !== null) deleteData(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDelete]);
  return (
    <div>
      <Breadcrumbs pageName="Booking Details" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">Details</h3>
        </div>

        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <div className="flex flex-col">
            <div className="px-6.5 py-2">
              <label className="mb-2.5 block text-black dark:text-white">
                Check In
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {formatDateString(data.startDate)} - {data.startTime}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-6.5 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Check Out
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {formatDateString(data.endDate)} - {data.endTime}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-6.5 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Boooked By
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {data.buyerName}
              </p>
            </div>
          </div>

          <div className="flex flex-col h-auto col-span-2">
            <div className="px-6.5 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Beds
              </label>

              <div className="w-full grid grid-cols-5 gap-5 h-auto rounded bg-transparent pb-3 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {data.bed.map((itm) => (
                  <div className="bg-ash relative border-[1px] border-semiash p-2 rounded-md w-full h-auto flex items-center justify-start flex-col">
                    <div className="w-auto h-auto pt-8">
                      <img className="object-cover" src={IMG.BED} alt="" />
                    </div>

                    <div className="flex flex-col justify-center items-center ">
                      <p className="text-lg font-bold font-muli mb-1 mt-4">
                        {itm.bedName}
                      </p>
                      <p className="text-md font-light font-muli mb-5 italic">
                        [ {itm.buyerName} ]
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        handleCancelBed(itm);
                      }}
                      className="border-red font-semibold border-[1px] rounded-md text-red w-full py-0.5"
                    >
                      REMOVE
                    </button>
                  </div>
                ))}
                {/* {data.bed.map((itm) => (
                  <div className="flex justify-between items-center text-black w-full p-4 border-stroke border-[1px] rounded-md">
                    <div>
                      <p>Bed: {itm.bedName}</p>
                      <p>Booked By :{itm.buyerName}</p>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          handleCancelBed(itm);
                        }}
                        className="border-red font-semibold border-[1px] rounded-md text-red w-full py-0.5 px-10"
                      >
                        REMOVE
                      </button>
                    </div>
                  </div>
                ))} */}
                {data.bed.length === 0 && (
                  <div className=" text-black w-full p-4 border-stroke border-[1px] rounded-md">
                    <p>0 Bed</p>
                    <p>No data selected</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col h-auto col-span-2">
            <div className="px-6.5 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Amenities
              </label>

              <div className="w-full grid grid-cols-5 gap-5 h-auto rounded bg-transparent pb-3 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {data.amenities.map((itm) => (
                  <div className="bg-ash relative border-[1px] border-semiash p-2 rounded-md w-full h-auto flex items-center justify-start flex-col">
                    <div className="w-auto h-auto pt-8">
                      <img className="object-cover w-15" src={IMG.ACT} alt="" />
                    </div>

                    <div className="flex flex-col justify-center items-center ">
                      <p className="text-lg font-bold font-muli mb-1 mt-4">
                        {itm.activity}
                      </p>
                      <p className="text-md font-light font-muli mb-5 italic">
                        [ {itm.buyerName} ]
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        handleCancelAme(itm);
                      }}
                      className="border-red font-semibold border-[1px] rounded-md text-red w-full py-0.5"
                    >
                      REMOVE
                    </button>
                  </div>
                  // <div className=" text-black w-full p-4 border-stroke border-[1px] rounded-md">
                  //   <p>Activity: {itm.activity}</p>
                  //   <p>Booked By :{itm.buyerName}</p>
                  // </div>
                ))}
                {data.amenities.length === 0 && (
                  <div className=" text-black w-full p-4 border-stroke border-[1px] rounded-md">
                    <p>0 Amenities</p>
                    <p>No data selected</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center py-5">
          <button
            onClick={() => {
              handleRemoveBooking();
            }}
            className="border-red bg-red font-bold border-[1px] rounded-md text-lg text-white py-2 px-10"
          >
            REMOVE THE WHOLE BOOKING
          </button>
        </div>
      </div>
    </div>
  );
}

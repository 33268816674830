import React from "react";
import DropdownUser from "./DropdownUser";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className="bg-primary h-auto w-full flex justify-center items-center py-2 px-5">
      <div className="w-full w-[80%] max-w-screen-2xl flex justify-between items-center">
        <Link to="/">
          <p className="text-white text-lg">Chaletfatma</p>
        </Link>
        <div>
          <DropdownUser />
        </div>
      </div>
    </div>
  );
}
